export const textPattern = '^((\\S)|(\\S.*\\S))$'
export const partialTextPattern = '^((\\S?)|(\\S.*))$'

export const phonePattern = '^48[0-9]{9}$'
export const partialPhonePattern = new RegExp(/^(4(8[0-9]{0,9})?)?$/, 'u')

export const emailPattern =
    "^(?:[a-z0-9!#$%&'\\*\\+\\/=\\?\\^_`\\{\\|\\}~\\-]+(?:\\.[a-z0-9!#\\$%&'\\*\\+\\/=\\?\\^_`\\{\\|\\}~\\-]+)*|\"" +
    '(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")' +
    '@(?:(?:[a-z0-9](?:[a-z0-9\\-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9\\-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]' +
    '|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9\\-]*[a-z0-9]:' +
    '(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$'

export const streetNumberPattern = '^([1-9][0-9]*[a-zA-Z]*(([/]|( m\\. ?))[1-9][0-9]*)?)?$'
export const partialStreetNumberPattern = '^([1-9][0-9]*[a-zA-Z]*(([/]|( m\\. ?))[1-9][0-9]*)?)?$'

export const zipCodePattern = '^[0-9]{2}-[0-9]{3}$'
export const partialZipCodePattern = /^(([0-9]{0,2})|([0-9]{2}(-[0-9]{0,3})?))$/

export const nipPattern = '^[0-9]{10}$'
export const partialNIPPattern = '^[0-9]{0,10}$'

const domainSegment = '[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?'
const partialDomainSegment = '([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9]?)?)?'

export const domainSearchPattern = `^(${domainSegment}\\.)*${domainSegment}$`

export const domainPattern = `^(${domainSegment}\\.)+${domainSegment}$`
export const partialDomainPattern = `^(${domainSegment}\\.)*${partialDomainSegment}$`

const textRegexp = new RegExp(textPattern, 'u')
const phoneRegexp = new RegExp(phonePattern, 'u')
const emailRegexp = new RegExp(emailPattern, 'u')
const streetNumberRegexp = new RegExp(streetNumberPattern, 'u')
const nipRegexp = new RegExp(nipPattern, 'u')
const zipCodeRegexp = new RegExp(zipCodePattern, 'u')
const domainRegexp = new RegExp(domainPattern, 'u')
export const partialDomainRegexp = new RegExp(partialDomainPattern, 'u')
export const domainSearchRegexp = new RegExp(domainSearchPattern, 'u')

export const isValidText = (value: string): boolean => textRegexp.test(value)
export const isValidPhone = (value: string): boolean => phoneRegexp.test(value)
export const isValidEmail = (value: string): boolean => emailRegexp.test(value)
export const isValidStreetNumber = (value: string): boolean => streetNumberRegexp.test(value)
export const isValidZipCode = (value: string): boolean => zipCodeRegexp.test(value)
export const isValidDomain = (value: string): boolean =>
    value.length >= 4 && value.length <= 253 && domainRegexp.test(value)

export const normalizeText = (text: string): string => {
    return text.trim()
}

export const normalizeNIP = (nip: string): string => nip.replace(/-/g, '')

export const validateNIP = (nip: string): string | true => {
    if (!nipRegexp.test(nip)) return 'format'
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7, -1]
    const checksum = normalizeNIP(nip)
        .split('')
        .map((digit, index) => (digit.charCodeAt(0) - '0'.charCodeAt(0)) * weights[index])
        .reduce((a, b) => a + b, 0)
    return checksum % 11 != 0 ? 'checksum' : true
}

export const filterByPartialRegExp = (
    partialRegexp: RegExp,
    text: string,
    autocomplete?: (newValue: string, oldValue: string) => string,
): string => {
    if (partialRegexp.test(text)) return text
    return text.split('').reduce((fragment, nextChar) => {
        const more = autocomplete ? autocomplete(fragment + nextChar, fragment) : fragment + nextChar
        return partialRegexp.test(more) ? more : fragment
    }, '')
}
