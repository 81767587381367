import { createErrorResponse, UnexpectedError } from 'api/errors'

class FetchData {
    static async fetch(url: string, options: Record<string, unknown> = {}): Promise<Response | Error> {
        return fetch(url, {
            credentials: 'same-origin',
            ...options,
        })
            .then(async (response) => {
                if (!response.ok) return createErrorResponse(response.status, response.statusText)
                return response.json()
            })
            .catch((error) => {
                return error
            })
    }

    public static async list(endpointUrl: string): Promise<string[] | Error> {
        const response = await FetchData.fetch(endpointUrl)
        return response as unknown as string[]
    }
}

async function performFetch(url: string, fetchOptions: Record<string, unknown>): Promise<Response> {
    return fetch(url, { credentials: 'same-origin', ...fetchOptions })
}

export async function basicFetch(
    url: string,
    options: Record<string, unknown> = {},
): Promise<[number, unknown] | Error> {
    const { allowErrors, ...otherOptions } = options
    return performFetch(url, otherOptions)
        .then(async (response) => {
            if (allowErrors && (allowErrors as number[]).indexOf(response.status) >= 0) {
                /* pass */
            } else if (!response.ok) {
                return createErrorResponse(response.status, response.statusText)
            }
            return [response.status, (await response.json()) as unknown]
        })
        .catch((error) => {
            return error
        })
}

export async function apiFetchEmptyResponse(url: string, options: Record<string, unknown> = {}): Promise<void> {
    const { when404, ...fetchOptions } = options
    return performFetch(url, fetchOptions).then(async (response) => {
        if (response.status === 404 && when404 !== undefined) {
            return
        }
        if (!response.ok) throw createErrorResponse(response.status, response.statusText)
    })
}

export async function apiFetch<T>(url: string, options: Record<string, unknown> = {}): Promise<T> {
    const { responseMapper, when404, ...fetchOptions } = options
    const mapperFunction = responseMapper as (json: unknown) => T
    return performFetch(url, fetchOptions).then(async (response) => {
        if (response.status === 404 && when404 !== undefined) {
            return when404 as T
        }
        if (!response.ok) throw createErrorResponse(response.status, response.statusText)
        const json = (await response.json()) as unknown
        return responseMapper ? mapperFunction(json) : (json as T)
    })
}

const getErrorInfo = (error: Error): [string, string, string] => {
    if (error instanceof UnexpectedError) {
        const { component, text } = error
        return [component, text, window.location.href]
    } else {
        return ['unknown', error.toString(), window.location.href]
    }
}

export function reportError(error: Error): void {
    const [component, text, url] = getErrorInfo(error)
    apiFetch<void>(
        `/api/reportError` +
            `?c=${encodeURIComponent(component)}` +
            `&i=${encodeURIComponent(text)}` +
            `&u=${encodeURIComponent(url)}`,
        { method: 'POST' },
    ).catch(() => {
        /* ignore errors in error reporting */
    })
}

export default FetchData
