import { TFunction } from 'i18next'

export type ValidationDetail = {
    type: string
    loc: string[]
    msg: string
}

export class RegisterValidationError {
    detail: ValidationDetail[] = []

    public constructor(content: RegisterValidationError) {
        this.detail = [...content.detail]
    }
}

export class NetworkError extends Error {
    public status: number

    public statusText: string

    public constructor(status: number, text: string) {
        super('NetworkError')
        this.status = status
        this.statusText = text
    }
}

export class AccessError extends NetworkError {}

export class ForbiddenError extends AccessError {
    public constructor(text: string) {
        super(403, text)
    }
}

export class UnauthorizedError extends AccessError {
    public constructor(text: string) {
        super(401, text)
    }
}

export class NotFoundError extends AccessError {
    public constructor(text: string) {
        super(404, text)
    }
}

export class UnexpectedError extends Error {
    public component: string
    public text: string
    public constructor(component_: string, text_: string) {
        super(`at ${component_}: ${text_}`)
        this.component = component_
        this.text = text_
    }
}

function createErrorResponse(status: number, statusText: string): NetworkError {
    if (status === 401) return new UnauthorizedError(statusText)
    if (status === 403) return new ForbiddenError(statusText)
    if (status === 404) return new NotFoundError(statusText)
    return new NetworkError(status, statusText)
}

function getMessageForError(error: Error, t: TFunction): string {
    if (error instanceof UnauthorizedError) {
        window.location.href = '/logout/'
        return ''
    } else if (error instanceof ForbiddenError) {
        return t('error-access', { text: error.statusText })
    } else if (error instanceof NetworkError) {
        return t('error-network', { text: error.statusText, status: error.status })
    } else if (error instanceof NotFoundError) {
        return t('error-not-found', { text: error.statusText, status: error.status })
    }
    return t('error-application', { text: error })
}

const errorsNotReported = [UnauthorizedError, ForbiddenError, NotFoundError, NetworkError]

function shouldBeReported(error: Error): boolean {
    return !errorsNotReported.some((e) => error instanceof e)
}

export { createErrorResponse, getMessageForError, shouldBeReported }
