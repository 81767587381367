import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import 'i18n'
import GlobalStyle from 'global-style'
import Header from 'components/header'
import Footer from 'components/footer'
import Main from 'components/main'
import Loading from 'components/loading'
import WithSkipLinks from 'components/skip-links'
import { translateWindowTitle } from 'api/language-patch'
import { Paths } from './routing'
import NavMenu from 'components/navmenu/navmenu'
import CatchAllErrors, { CatchWait, PassEventErrorsToBoundary } from 'components/error-handler'

import useAccessCheck from 'hooks/access-check'
import SessionInformationContext from 'api/access-context'

const Content = (): ReactElement => {
    const { t } = useTranslation()
    return (
        <Router>
            <Routes>
                {Paths.map(({ path, title, fullWidth, component }, key) => {
                    const Content = component
                    /* eslint-disable @typescript-eslint/no-explicit-any */
                    return (
                        <Route
                            path={path}
                            element={
                                <WithSkipLinks>
                                    <Header title={t(title)} key={key.toString()}>
                                        <NavMenu />
                                    </Header>
                                    <Main isHome={title === 'header-home'} fullWidth={fullWidth}>
                                        <CatchAllErrors>
                                            <PassEventErrorsToBoundary />
                                            <CatchWait fallback={<Loading />}>
                                                <Content />
                                            </CatchWait>
                                        </CatchAllErrors>
                                    </Main>
                                    <Footer />
                                </WithSkipLinks>
                            }
                            key={key.toString()}
                        />
                    )
                    /* eslint-enable @typescript-eslint/no-explicit-any */
                })}
            </Routes>
        </Router>
    )
}

const queryClient = new QueryClient()

const SessionAwareContent = (): ReactElement => {
    const sessionInfo = useAccessCheck()
    if (sessionInfo === undefined) {
        return <Loading />
    }
    return (
        <SessionInformationContext value={sessionInfo}>
            <Content />
        </SessionInformationContext>
    )
}

function App(): ReactElement {
    translateWindowTitle()
    return (
        <>
            <GlobalStyle />
            <CatchAllErrors>
                <QueryClientProvider client={queryClient}>
                    <CatchWait fallback={<Loading />}>
                        <SessionAwareContent />
                    </CatchWait>
                </QueryClientProvider>
            </CatchAllErrors>
        </>
    )
}

export default App
