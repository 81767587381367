import styled from 'styled-components'
import { CONTENT_FONT, CONTENT_ERROR_TEXT_COLOR, MOBILE } from 'global-style'

export const ErrorInformationStyled = styled.div`
    padding: 3.375rem 0;

    ${MOBILE} {
        padding: 2rem 0;
    }
`

export const ErrorInformationTitle = styled.h1`
    color: ${CONTENT_ERROR_TEXT_COLOR};
    font-family: ${CONTENT_FONT};
    font-weight: bold;
    font-size: 2.25rem;

    ${MOBILE} {
        font-size: 1.5rem;
    }
`

export const ErrorInformationMessage = styled.p`
    color: ${CONTENT_ERROR_TEXT_COLOR};
    font-family: ${CONTENT_FONT};
    font-size: 1.5rem;

    ${MOBILE} {
        font-size: 1rem;
    }
`
