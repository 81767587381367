import { normalizeNIP, validateNIP } from 'api/validation'
import { CompanyDataFromNIP } from 'api/nip'
import { useConditionalAPIQuery } from 'hooks/api-query'

const useCompanyDataFromNIP = (NIP: string): CompanyDataFromNIP | null => {
    const cleanedUpNIP = normalizeNIP(NIP)
    const nipIsValid = validateNIP(cleanedUpNIP) === true
    const result = useConditionalAPIQuery<CompanyDataFromNIP[]>(
        `/api/find/nip/${encodeURIComponent(cleanedUpNIP)}`,
        {},
        nipIsValid,
    )
    if (!nipIsValid || result === null) return null
    if (result.length === undefined || result.length < 1) return null
    const data = result[0]
    if (data.nip !== NIP) return null
    return data
}

export default useCompanyDataFromNIP
