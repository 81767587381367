import React, { ReactElement, ReactNode, useRef, useEffect, useState, useContext, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useCurrentLanguage } from 'hooks/use-current-language'

import { StyledLink, StyledListElement, StyledList } from './list-styled'

type TOCEntry = {
    caption: string
    anchor: string
}

type TableOfContents = {
    anchors: Record<string, boolean>
    contents: TOCEntry[]
    tailContents: TOCEntry[]
}

type AddToTOCOptions = {
    last?: boolean
    focus?: boolean
}

type AddToTableOfContents = (caption: string, anchor: string, options?: AddToTOCOptions) => void

const TableOfContentsContext = createContext<AddToTableOfContents>(() => {
    return
})

const mergeTOC = (
    old: TableOfContents,
    caption: string,
    anchor: string,
    options?: AddToTOCOptions,
): TableOfContents => {
    if (old.anchors[anchor] !== undefined) return old
    const focus = options?.focus === true
    if (options?.last === true) {
        return {
            anchors: { ...old.anchors, [anchor]: focus },
            contents: old.contents,
            tailContents: [...old.tailContents, { caption, anchor }],
        }
    } else {
        return {
            anchors: { ...old.anchors, [anchor]: focus },
            contents: [...old.contents, { caption, anchor }],
            tailContents: old.tailContents,
        }
    }
}

const tryFocus = (tableOfContents: TableOfContents, anchor: string): boolean => {
    const ref = tableOfContents.anchors[anchor]
    if (ref === true) {
        const element = document.getElementById(anchor.substr(1))
        element?.focus()
        return true
    }
    return false
}

const WithSkipLinks = ({ children }: { children: ReactNode }): ReactElement => {
    const [tableOfContents, setTableOfContents] = useState<TableOfContents>({
        anchors: {},
        contents: [],
        tailContents: [],
    })
    const { t } = useTranslation()
    const { pathname, hash } = useLocation()
    const language = useCurrentLanguage()
    const list = useRef<HTMLUListElement | null>(null)

    useEffect(() => {
        setTableOfContents({ anchors: {}, contents: [], tailContents: [] })
    }, [pathname, hash, language])

    useEffect(() => {
        if (list.current) {
            if (!tryFocus(tableOfContents, decodeURIComponent(hash))) {
                list.current.focus()
            }
        }
    }, [tableOfContents, hash])

    const addToTOC = (caption: string, anchor: string, options?: AddToTOCOptions): void => {
        setTableOfContents((old) => mergeTOC(old, caption, anchor, options))
    }

    return (
        <TableOfContentsContext.Provider value={addToTOC}>
            <StyledList id='skiplist' ref={list} tabIndex={-1}>
                {[...tableOfContents.contents, ...tableOfContents.tailContents].map(({ caption, anchor }) => (
                    <StyledListElement key={anchor}>
                        <StyledLink
                            href={anchor}
                            tabIndex={0}
                            onClick={() => {
                                tryFocus(tableOfContents, anchor)
                            }}
                        >
                            {t('jump to', { where: caption })}
                        </StyledLink>
                    </StyledListElement>
                ))}
            </StyledList>
            {children}
        </TableOfContentsContext.Provider>
    )
}

const useAddToTableOfContents = (): AddToTableOfContents => {
    const addToTOC = useContext(TableOfContentsContext)
    return addToTOC
}

export default WithSkipLinks
export { useAddToTableOfContents }
