import React, { ReactElement, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImagesPath } from 'paths'
import { Crossmark, MobileImg, StyledLogo } from '../header/header'
import { useSessionInformation } from 'api/access-context'
import SessionTimer from 'components/sessiontimer'
import { NavigationSection } from 'components/section'
import { RefreshSessionFunction } from 'hooks/access-check'
import {
    AccountElementsUpper,
    DivArrowDown,
    StyledLanguageSelector,
    LinkList,
    StyledLink,
    LogIn,
    AccountElementsLower,
    AccountIcon,
    Menu,
    StyledListItem,
    StyledListItemLoginInfo,
    Nav,
    Account,
    ArrowDownStyled,
    DomainLink,
    DomainLogo,
    LoginInfo,
    Logo,
    LogoLink,
    LogOut,
    StyledSessionTimer,
    DivLinkList,
    MobileMenuBorder,
    MobileOnly,
    LoginName,
    ListItemButton,
    Row,
    RowMobile,
    Wrap,
    Background,
    MenuMobileButton,
} from 'components/navmenu/navmenu-styled'

const ArrowDownUp = (): ReactElement => {
    const { t } = useTranslation()
    const [state, setState] = useState<boolean>(false)
    const element = document.getElementById('arrow-down-up')
    return (
        <DivArrowDown>
            <ArrowDownStyled
                type='button'
                className={state ? 'open' : 'closed'}
                id={'arrow-down-up'}
                title={t('toggle-account-menu')}
                onClick={() => {
                    setState(!state)
                    state ? (element ? element.blur() : null) : null
                }}
            >
                {/*empty xml body*/}
            </ArrowDownStyled>
        </DivArrowDown>
    )
}

function blurActiveElement(): void {
    const element = document.activeElement as HTMLElement
    element?.blur()
}

const ListItem = ({
    target,
    title,
    onlyMobile,
    mobileBorder,
}: {
    target: string
    title: string
    onlyMobile: boolean
    mobileBorder?: boolean
}): ReactElement => {
    const { t } = useTranslation()
    const text = t(title)
    return (
        <StyledListItem className={onlyMobile ? 'onlyMobile' : 'desktopAndMobile'}>
            <StyledLink
                to={target}
                data-index={text}
                onMouseDown={(event) => event.preventDefault()}
                onClick={blurActiveElement}
            >
                {text}
            </StyledLink>
            {mobileBorder ? <MobileMenuBorder /> : null}
        </StyledListItem>
    )
}

export const Links = ({
    username,
    refreshSession,
}: {
    username: string | null
    refreshSession: RefreshSessionFunction | null
}): ReactElement => {
    const { t } = useTranslation()
    const loggedIn = refreshSession !== null
    return (
        <DivLinkList>
            <Row>
                <Crossmark type='button' onClick={blurActiveElement}>
                    &#10005;
                </Crossmark>
            </Row>
            <LinkList>
                {loggedIn ? (
                    <>
                        <StyledListItemLoginInfo>
                            <MobileOnly>
                                <LoginInfo>
                                    <LoginName>
                                        {t('login-info')}
                                        {username ? username : ''}
                                    </LoginName>
                                    {t('login-info-session-time')} <SessionTimer />
                                </LoginInfo>
                                <ListItemButton
                                    type='button'
                                    title={t('session-left')}
                                    onClick={() => {
                                        refreshSession()
                                    }}
                                >
                                    {t('nav-session')}
                                </ListItemButton>
                            </MobileOnly>
                        </StyledListItemLoginInfo>
                        <ListItem target={'/logout'} title={'nav-logout'} onlyMobile={true} mobileBorder={true} />
                        <ListItem target='/domains/' title={t('nav-domains')} onlyMobile={false} />
                    </>
                ) : (
                    <ListItem target={'/login/'} title={t('nav-login')} onlyMobile={true} mobileBorder={true} />
                )}
                <ListItem target='/price-list/' title={t('nav-price-list')} onlyMobile={false} />
                <ListItem target='/registration-rules/' title={t('nav-registration-rules')} onlyMobile={false} />
                <ListItem target='/faq/' title={t('nav-faq')} onlyMobile={false} />
            </LinkList>
        </DivLinkList>
    )
}

const NavMenu = (): ReactElement => {
    const { t } = useTranslation()
    const sessionInfo = useSessionInformation()
    const loggedIn = sessionInfo !== false
    return (
        <Nav>
            <Logo>
                <LogoLink href={'https://task.gda.pl/'}>
                    <StyledLogo alt={t('nav-task')} src={`${ImagesPath}/task-logo-darkblue.svg`} />
                </LogoLink>
                <DomainLink to={'/'}>
                    <DomainLogo>{t('header-domains')}</DomainLogo>
                </DomainLink>
            </Logo>
            <Menu>
                <MenuMobileButton
                    type='button'
                    onClick={(event: MouseEvent<HTMLButtonElement>) => event.currentTarget.focus()}
                    title={t('mobile-menu')}
                >
                    <MobileImg src={`${ImagesPath}/harmonijka_o.svg`} alt='' />
                </MenuMobileButton>
                <NavigationSection />
                <Links
                    username={sessionInfo ? sessionInfo.username : null}
                    refreshSession={sessionInfo ? sessionInfo.refreshSession : null}
                />
            </Menu>
            <Wrap>
                <StyledLanguageSelector />
                {!loggedIn && <LogIn to='/login/'>{t('nav-login')}</LogIn>}
                {loggedIn && (
                    <RowMobile>
                        <Background title={'navigation-logout'}>
                            <AccountElementsUpper>
                                <AccountIcon src={`${ImagesPath}/account-icon.svg`} alt={'account-icon'} />
                                <Account to={'/oauth/account'} title={t('nav-account')}>
                                    {sessionInfo ? sessionInfo.username : ''}
                                </Account>
                                <ArrowDownUp />
                            </AccountElementsUpper>
                            <AccountElementsLower>
                                <LogOut to='/logout/' rel={'alternate'} title={'logout'}>
                                    {t('nav-logout')}
                                </LogOut>
                            </AccountElementsLower>
                        </Background>
                        <StyledSessionTimer />
                    </RowMobile>
                )}
            </Wrap>
        </Nav>
    )
}

export default NavMenu
