import styled from 'styled-components'

import {
    SKIP_LINK_TEXT,
    SKIP_LINK_BACKGROUND,
    SKIP_LINK_FONT_SIZE,
    FOCUS_OUTLINE,
    FOCUS_OUTLINE_WIDTH,
} from 'global-style'

const StyledLink = styled.a`
    top: ${FOCUS_OUTLINE_WIDTH};
    left: ${FOCUS_OUTLINE_WIDTH};
    position: fixed;
    background-color: ${SKIP_LINK_BACKGROUND};
    width: 0px;
    height: 0px;
    overflow: hidden;
    font-size: ${SKIP_LINK_FONT_SIZE};
    color: ${SKIP_LINK_TEXT};
    text-decoration: none;
    box-sizing: border-box;
    &:visited {
        color: ${SKIP_LINK_TEXT};
        text-decoration: none;
    }
    &:focus {
        display: flex;
        outline: ${FOCUS_OUTLINE};
        box-shadow: none;
        border-color: transparent;
        width: auto;
        height: auto;
        padding: 0.5rem;
        text-align: center;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }
`

const StyledListElement = styled.li``

const StyledList = styled.ul`
    top: 0;
    left: 0;
    position: absolute;
    list-style: none;
    &:focus {
        outline: none;
    }
`

export { StyledLink, StyledListElement, StyledList }
