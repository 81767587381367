import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ImagesPath } from 'paths'
import { PageHeader } from 'components/section'

import {
    MOBILE,
    MAX_DESKTOP_CONTENT_WIDTH_STYLE,
    CONTENT_TEXT_COLOR,
    CONTENT_FONT,
    CONTENT_BACKGROUND_COLOR,
    CONTENT_ALTERNATE_BACKGROUND_COLOR,
    CONTENT_ADVERTISEMENT_TEXT_COLOR,
    CONTENT_ADVERTISEMENT_BACKGROUND_COLOR,
} from 'global-style'
import CheckDomainForm from 'components/check-domain-form'

const ContentAdvertisementWrapper = styled.main`
    display: block;
    flex-grow: 0;
    color: ${CONTENT_ADVERTISEMENT_TEXT_COLOR};
    background-color: ${CONTENT_ADVERTISEMENT_BACKGROUND_COLOR};
    font-family: ${CONTENT_FONT};
    font-weight: normal;
`

const ContentWrapper = styled.main`
    display: block;
    flex-grow: 1;
    color: ${CONTENT_TEXT_COLOR};
    background-color: ${CONTENT_BACKGROUND_COLOR};
    font-family: ${CONTENT_FONT};
    font-weight: normal;
    ${MOBILE} {
        padding: 3rem 0 1rem 0;
    }
`

const AdvertisementText = styled.div`
    margin: 3.75rem 0 0 0;
    max-width: 35rem;
    font-size: 3.125rem;
    ${MOBILE} {
        margin: 0 auto 0 auto;
        font-size: 1.375rem;
        text-align: center;
        max-width: 18rem;
        display: initial;
        grid-area: text;
    }
`

const AdvertisementHeader = styled(PageHeader)`
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: ${CONTENT_FONT};
    font-weight: bold;
    font-size: 1em;
    ${MOBILE} {
        font-size: 2rem;
    }
`

const AdvertisementContent = styled.div`
    margin: 0;
    padding: 1.125rem 0 0 0;
`

const AdvertisementImage = styled.img`
    max-width: 26.25rem;
    min-width: 10rem;
    margin: 0 3.5rem 0 0;
    ${MOBILE} {
        margin: 0.5rem auto 0 auto;
        width: 100%;
        max-height: 19rem;
        min-height: 4rem;
        grid-area: image;
    }
`

const StyledAdvertisementRow = styled.div`
    max-width: calc(${MAX_DESKTOP_CONTENT_WIDTH_STYLE} + 3rem);
    margin: 0 auto 0 auto;
    padding: 3rem 1.5rem 0 1.5rem;
    display: flex;
    justify-content: space-between;
    ${MOBILE} {
        padding-top: 3.75rem;
        flex-direction: column;
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: minmax(1rem, 1fr);
        grid-template-areas:
            'image'
            'text';
    }
`
const AdvertisementRow = (): ReactElement => {
    const { t } = useTranslation()
    return (
        <StyledAdvertisementRow>
            <AdvertisementText>
                <AdvertisementHeader text={t(`main-ad-header`)} />
                <AdvertisementContent>{t(`main-ad-text`)}</AdvertisementContent>
                <CheckDomainForm />
            </AdvertisementText>
            <AdvertisementImage src={`${ImagesPath}/dns.svg`} alt='' />
        </StyledAdvertisementRow>
    )
}

const ContentRow = styled.div`
    overflow-x: auto;
    margin: 0;
    --padding-width: max(1.5rem, 50vw - 0.5 * ${MAX_DESKTOP_CONTENT_WIDTH_STYLE} - 1.5rem);
    padding: 0 var(--padding-width);
    ${MOBILE} {
        padding: 0 1rem;
    }
`

const AlternateContentRow = styled(ContentRow)`
    background-color: ${CONTENT_ALTERNATE_BACKGROUND_COLOR};
`

type PMain = {
    children?: ReactNode
    fullWidth?: boolean
    isHome: boolean
}

const Main = ({ isHome, fullWidth, children }: PMain): ReactElement => {
    if (isHome) {
        return (
            <ContentAdvertisementWrapper>
                <AdvertisementRow />
            </ContentAdvertisementWrapper>
        )
    }
    if (fullWidth === true) {
        return <ContentWrapper>{children}</ContentWrapper>
    }
    return (
        <ContentWrapper>
            <ContentRow>{children}</ContentRow>
        </ContentWrapper>
    )
}

export default Main
export { ContentRow, AlternateContentRow }
