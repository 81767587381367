import { useEffect, useState } from 'react'

const tokenValidityMargin = 20 // [seconds]

export type BackendSessionInformation = {
    username: string
    email: string
    ttl: number
    refreshed_ttl: number
}

export type RefreshSessionFunction = () => Promise<void>

export type SessionInformation = {
    username: string
    email: string
    expires: Date
    refreshed_ttl: number
    refreshSession: RefreshSessionFunction
}

export type AccessCheckResult = undefined | false | SessionInformation

const queryAccess = async (refreshFunction: RefreshSessionFunction): Promise<null | SessionInformation> => {
    return await fetch('/access-check/', {
        credentials: 'same-origin',
        redirect: 'error',
    })
        .then(async (response): Promise<SessionInformation | null> => {
            if (!response.ok) {
                return null
            }
            const fromBackend = await response.json()
            return {
                username: fromBackend.username,
                email: fromBackend.email,
                expires: new Date(Date.now() + (fromBackend.ttl - tokenValidityMargin) * 1000),
                refreshed_ttl: fromBackend.refreshed_ttl,
                refreshSession: refreshFunction,
            }
        })
        .catch(() => {
            return null
        })
}

export const useAccessCheck = (): AccessCheckResult => {
    const [accessInfo, setAccessInfo] = useState<AccessCheckResult>(undefined)

    useEffect((): void => {
        const refreshSession = async (): Promise<void> => {
            const result = await queryAccess(refreshSession)
            setAccessInfo(result === null ? false : result)
        }
        refreshSession()
    }, [])
    return accessInfo
}

export default useAccessCheck
