import React, { ReactElement, ReactNode, Suspense, useEffect } from 'react'
import { ErrorBoundary, FallbackProps, useErrorBoundary } from 'react-error-boundary'

import { shouldBeReported } from 'api/errors'
import { reportError } from 'api/fetch'
import ErrorInformation from 'components/error-information'

const markError = (error: Error, info: { componentStack: string }): void => {
    info
    const error_ = error as Error & { handled?: boolean }
    error_.handled = true
}

const ShowError = ({ error }: FallbackProps): ReactElement => {
    if (shouldBeReported(error)) {
        reportError(error)
    }
    return <ErrorInformation error={error} />
}

const CatchAllErrors = ({ children }: { children?: ReactNode }): ReactElement => {
    return (
        <ErrorBoundary onError={markError} FallbackComponent={ShowError}>
            {children}
        </ErrorBoundary>
    )
}

const CatchErrors = ({
    fallback,
    children,
}: {
    fallback: React.ComponentType<React.PropsWithChildren<FallbackProps>>
    children?: ReactNode
}): ReactElement => {
    return (
        <ErrorBoundary onError={markError} FallbackComponent={fallback !== undefined ? fallback : ShowError}>
            {children}
        </ErrorBoundary>
    )
}

const CatchWait = ({ fallback, children }: { fallback: ReactNode; children?: ReactNode }): ReactElement => {
    return <Suspense fallback={<>{fallback}</>}>{children}</Suspense>
}

const PassEventErrorsToBoundary = (): ReactElement | null => {
    const { showBoundary } = useErrorBoundary()
    const handler = (evt: ErrorEvent): void => {
        setTimeout(() => {
            const error_ = evt.error as Error & { handled?: boolean }
            if (error_.handled !== true) {
                showBoundary(evt.error)
            }
        }, 0)
    }
    useEffect(() => {
        window.addEventListener('error', handler)
        window.addEventListener('unhandledrejection', (evt) => showBoundary(evt.reason))

        return () => {
            window.removeEventListener('error', handler)
            window.removeEventListener('unhandledrejection', (evt) => showBoundary(evt.reason))
        }
    })
    return null
}

export default CatchAllErrors
export { CatchWait, CatchErrors, PassEventErrorsToBoundary }
