import React, { ReactElement, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { useAddToTableOfContents } from 'components/skip-links'

const ForScreenReader = css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    white-space: nowrap;
`

const H2ForScreenReader = styled.h2`
    ${ForScreenReader}
`

const H1 = styled.h1``

const H2 = styled.h2``

const PageHeader = ({
    text,
    children,
    className,
}: {
    text: string
    children?: ReactNode
    className?: string
}): ReactElement => {
    const addToTOC = useAddToTableOfContents()
    const id = `sec1:${text.replaceAll(' ', '_')}`
    useEffect(() => addToTOC(text, `#${id}`))
    return (
        <H1 id={id} className={className}>
            {children !== undefined ? children : text}
        </H1>
    )
}

const NavigationSection = (): ReactElement => {
    const { t } = useTranslation()
    const addToTOC = useAddToTableOfContents()
    const text = t('navigation section')
    useEffect(() => addToTOC(text, '#sec:nav'))
    return <H2ForScreenReader id='sec:nav'>{text}</H2ForScreenReader>
}

const FooterSection = (): ReactElement => {
    const { t } = useTranslation()
    const addToTOC = useAddToTableOfContents()
    const text = t('footer section')
    useEffect(() => addToTOC(text, '#sec:footer', { last: true }))
    return <H2ForScreenReader id='sec:footer'>{text}</H2ForScreenReader>
}

type PContentSection = {
    text: string
    children?: ReactNode
    className?: string
    focusOnRef?: boolean
} & React.ComponentProps<typeof H2>

const ContentSection = ({ text, children, className, focusOnRef, ...rest }: PContentSection): ReactElement => {
    const addToTOC = useAddToTableOfContents()
    const id = `sec2:${text.replaceAll(' ', '_')}`
    useEffect(() => addToTOC(text, `#${id}`, { focus: focusOnRef }))
    return (
        <H2 id={id} className={className} {...rest}>
            {text}
            {children}
        </H2>
    )
}

export { NavigationSection, FooterSection, PageHeader, ContentSection }
