import styled from 'styled-components'
import {
    ACCENT_TYPEFACE,
    BUTTON_WHITE_TEXT_COLOR,
    FOCUS_OUTLINE_SECOND_COLOR,
    FOCUS_OUTLINE_SHADOW,
    FOCUS_OUTLINE_TYPE,
    FOCUS_OUTLINE_WIDTH,
    HEADER_ACCOUNT_MOBILE_INFO,
    HEADER_BACKGROUND_COLOR,
    HEADER_BUTTON_COLOR,
    HEADER_BUTTON_LOGIN_COLOR,
    HEADER_LOGO_BORDER_COLOR,
    HEADER_LOGO_COLOR,
    HEADER_LOGO_TYPEFACE,
    HEADER_TEXT_COLOR,
    MOBILE,
    NOT_MOBILE,
} from 'global-style'
import { Link } from 'react-router-dom'
import SessionTimer from '../sessiontimer'
import LanguageSelector from '../languageselector'
import { Crossmark, MobileImg } from '../header/header'

const ArrowDownStyled = styled.button`
    font-size: 1.375rem;
    background-color: ${HEADER_BUTTON_COLOR};
    border: none;
    color: ${BUTTON_WHITE_TEXT_COLOR};
    text-decoration: none;

    &::after {
        content: '▼';
    }

    &.open::after,
    div:hover > div > div > &::after,
    div:focus-within > div > div > &::after {
        content: '▲';
    }
`

const AccountElementsLower = styled.div`
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    background-color: ${HEADER_BUTTON_COLOR};
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
`

const LogoLink = styled.a`
    text-decoration: none;
    border: none;
    color: white;
    background: none;
    text-align: left;
    margin-top: 0.5rem;
    cursor: pointer;
`
const DomainLink = styled(Link)`
    text-decoration: none;
`

const LogOut = styled(Link)`
    background-color: ${HEADER_BUTTON_LOGIN_COLOR};
    min-height: 100%;
    color: ${BUTTON_WHITE_TEXT_COLOR};
    border: none;
    border-radius: 1.25rem;
    text-align: center;
    line-height: 1rem;
    font-family: ${ACCENT_TYPEFACE};
    font-size: 1.1rem;
    font-weight: bold;
    padding: 1.5rem 2rem 0.5rem 2rem;
    margin: 0;
    text-decoration: none;
    min-width: 8rem;
    height: 3.688rem;
`

const Account = styled(Link)`
    background-color: ${HEADER_BUTTON_COLOR};
    color: ${BUTTON_WHITE_TEXT_COLOR};
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    text-decoration: none;
    font-size: 1.375rem;
    padding: 0.4rem 0.1rem 0.2rem 0.1rem;
    margin: 0.8rem 0 0.6rem 0.4rem;
    overflow: hidden;
    max-width: 15rem;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const DivArrowDown = styled.div`
    border-radius: 2rem;
    padding: 1rem 1rem 0 1rem;
    background-color: ${HEADER_BUTTON_COLOR};
`
const LoginInfo = styled.div`
    width: 20.5rem;
    height: 4.813rem;
    background-color: ${HEADER_ACCOUNT_MOBILE_INFO};
    border-radius: 0.813rem;
    font-family: ${ACCENT_TYPEFACE};
    font-size: 1rem;
    color: white;
    text-align: center;
    padding-top: 1.2rem;
    ${NOT_MOBILE} {
        display: none;
        width: 0;
    }
`

const Background = styled.div`
    position: relative;
    color: white;
    border: none;
    border-radius: 1.25rem;
    padding: 0;
    margin: 0.7rem 0.5rem 2rem 1rem;
    line-height: 2rem;
    font-family: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    background-color: ${HEADER_BUTTON_COLOR};
    display: flex;
    width: fit-content;
    flex-direction: column;
    min-width: 14rem;
    height: 4rem;
    ${AccountElementsLower} {
        overflow: hidden;
        height: 0;
        padding: 0;
        margin: 0;
        min-height: 0;
    }
    &:focus-within {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ${AccountElementsLower} {
            padding: 1rem 1rem 2rem 1rem;
            height: auto;
        }
    }
    &:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        ${AccountElementsLower} {
            padding: 1rem 1rem 2rem 1rem;
            height: auto;
        }
    }
`

const LogIn = styled(Link)`
    background-color: ${HEADER_BUTTON_LOGIN_COLOR};
    display: block;
    color: ${BUTTON_WHITE_TEXT_COLOR};
    border: none;
    border-radius: 1.25rem;
    text-align: center;
    text-decoration: none;
    padding: 1.5rem 1rem 0.8rem 1rem;
    margin: 0.8rem 1rem 1rem 1rem;
    float: right;
    width: 10rem;
    line-height: 1rem;
    height: 3.688rem;
    font-family: ${ACCENT_TYPEFACE};
    font-size: 1.375rem;
    font-weight: bold;
    ${MOBILE} {
        display: none;
        width: 0;
        margin-top: 0;
        position: absolute;
        top: 4rem;
        left: 0;
        right: 0;
        min-height: 3.688rem;
        margin-left: auto;
        margin-right: auto;
    }
    &:focus {
        outline: ${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_TYPE} ${HEADER_TEXT_COLOR};
        box-shadow:
            ${FOCUS_OUTLINE_SHADOW},
            inset ${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_SECOND_COLOR},
            inset ${FOCUS_OUTLINE_WIDTH} -${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_SECOND_COLOR},
            inset -${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_SECOND_COLOR},
            inset -${FOCUS_OUTLINE_WIDTH} -${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_SECOND_COLOR};
    }
`

const AccountIcon = styled.img`
    width: 4rem;
    height: 3.3rem;
    padding: 0.8rem 0 0.2rem 0.8rem;
    margin: 0;
`

const DivLinkList = styled.div`
    margin-top: 1rem;
`

const LinkList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-items: left;
    justify-content: flex-end;
    ${MOBILE} {
        position: absolute;
        top: 4rem;
        right: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`

const StyledListItem = styled.li`
    list-style: none;
    box-sizing: content-box;
    line-height: 3.156rem;
    display: inline-block;
    text-align: center;
    margin: 0.8rem 0 1.5em 0;
    padding: 0.4rem 1.2rem 0.2rem 1.2rem;
    &.onlyMobile {
        display: grid;
        justify-content: center;
        flex-flow: column;
        text-align: center;
        ${NOT_MOBILE} {
            display: none;
            visibility: hidden;
            width: 0;
        }
    }
    ${MOBILE} {
        margin: 0.5rem;
        padding: 0;
    }
`

const StyledListItemLoginInfo = styled.li`
    list-style: none;
    text-align: center;
    box-sizing: content-box;
    ${MOBILE} {
        margin: 0.5rem;
        padding: 0;
    }
`

const StyledLink = styled(Link)`
    ${NOT_MOBILE} {
        margin: 0.8rem 0 1rem 0;
        text-align: center;
        box-sizing: border-box;
        font-size: 1.25rem;
        line-height: 1rem;
        color: ${HEADER_TEXT_COLOR};
        text-decoration: none;
        display: inline-block;
        font-family: ${ACCENT_TYPEFACE};
        font-weight: lighter;
        &::before {
            font-family: ${ACCENT_TYPEFACE};
            font-weight: bold;
            display: block;
            content: attr(data-index);
            height: 0;
            visibility: hidden;
            overflow: hidden;
        }
        &:hover {
            font-family: ${ACCENT_TYPEFACE};
            font-weight: bold;
            margin-bottom: calc(0.5rem - 1px);
            &::after {
                display: block;
                content: '';
                margin: 0.5rem 0.8rem 0 0.8rem;
                border-bottom: 1px solid ${HEADER_BUTTON_COLOR};
            }
        }
    }
    ${MOBILE} {
        color: ${BUTTON_WHITE_TEXT_COLOR};
        font-family: ${ACCENT_TYPEFACE};
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        padding: 0;
        margin: 0.5rem;
    }
`

const Row = styled.div`
    position: absolute;
    top: 0;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: flex-end;
    ${NOT_MOBILE} {
        width: 0;
        display: none;
    }
`
const Wrap = styled.div`
    ${NOT_MOBILE} {
    display: flex;
    flex-flow: row;
    right: 0;
    top: 0;
    float: right;
`
const StyledSessionTimer = styled(SessionTimer)`
    justify-content: center;
    width: 4rem;
    align-self: start;
`

const StyledLanguageSelector = styled(LanguageSelector)`
    padding: 0.938rem;
    ${MOBILE} {
        padding: 0;
    }
`
const AccountElementsUpper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`

const MenuMobileButton = styled.button`
    background-color: ${HEADER_BACKGROUND_COLOR};
    border: none;
    cursor: pointer;
    outline: none;
    ${NOT_MOBILE} {
        display: none;
    }
    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
`

const Menu = styled.div`
    border: none;
    ${MOBILE} {
        cursor: pointer;
        border: none;
        background-color: ${HEADER_BACKGROUND_COLOR};
        ${Crossmark} {
            display: none;
        }
        ${LinkList} {
            display: none;
        }
        &:focus-within {
            background-color: ${HEADER_BUTTON_LOGIN_COLOR};
            position: fixed;
            z-index: 2;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            ${LinkList} {
                display: flex;
                flex-flow: column;
            }
            ${MobileImg} {
                display: none;
            }
            ${Crossmark} {
                display: block;
            }
            ${MenuMobileButton} {
                background-color: ${HEADER_BUTTON_LOGIN_COLOR};
            }
        }
    }
`

const DomainLogo = styled.div`
    border-left: solid 1px ${HEADER_LOGO_BORDER_COLOR};
    margin-left: 0.5rem;
    margin-top: 1rem;
    border-spacing: 2rem;
    color: ${HEADER_LOGO_COLOR};
    font-family: ${HEADER_LOGO_TYPEFACE};
    font-weight: 700;
    font-size: 1.875rem;
    height: 3.5rem;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    text-indent: 1rem;
    ${MOBILE} {
        font-size: 1.3rem;
        padding-top: 0.2rem;
        padding-left: 0.2rem;
        height: 2.2rem;
        text-indent: 0.5rem;
    }
`
const Logo = styled.div`
    padding-top: 0.5rem;
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(2, auto);
    grid-template-areas: 'logo domains';
    ${LogoLink} {
        grid-area: logo;
    }
    ${DomainLink} {
        grid-area: domains;
    }
    ${MOBILE} {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        top: 0;
    }
`

const Nav = styled.nav`
    display: grid;
    width: 100%;
    margin: 19px 0 0 0;
    padding: 0;
    grid-template-areas: 'logo links lang button';
    grid-template-columns: min-content minmax(min-content, 1fr) min-content min-content;
    ${StyledLanguageSelector} {
        grid-area: lang;
    }
    ${Logo} {
        grid-area: logo;
    }
    ${Menu} {
        grid-area: links;
    }
    ${Background} {
        ${NOT_MOBILE} {
            margin-top: 1.3rem;
        }
    }
    ${StyledSessionTimer} {
        ${NOT_MOBILE} {
            margin-top: 1.5rem;
        }
    }
    ${LogIn} {
        grid-area: button;
        ${NOT_MOBILE} {
            margin-top: 1.5rem;
        }
    }
    ${MOBILE} {
        grid-template-columns: auto auto auto;
        width: 100%;
        margin: 0;
        justify-content: space-between;
        justify-items: center;
        grid-template-areas: 'lang logo links';
        display: grid;
    }
    ${StyledLanguageSelector} {
        grid-area: lang;
    }
    ${Logo} {
        grid-area: logo;
    }
    ${Menu} {
        grid-area: links;
    }
    ${LogIn} {
        grid-area: button;
    }
`
const RowMobile = styled.div`
    display: flex;
    flex-flow: row;
    ${MOBILE} {
        display: none;
        width: 0;
    }
`

const MobileOnly = styled.div`
    display: grid;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    ${NOT_MOBILE} {
        display: none;
        width: 0;
    }
`
const MobileMenuBorder = styled.div`
    ${MOBILE} {
        height: 1px;
        border-bottom: white dashed 1px;
    }
    ${NOT_MOBILE} {
        display: none;
    }
`

const LoginName = styled.div`
    font-weight: bold;
    margin-bottom: 0.2rem;
`

const ListItemButton = styled.button`
    color: ${BUTTON_WHITE_TEXT_COLOR};
    font-family: ${ACCENT_TYPEFACE};
    font-size: 1.5rem;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    padding: 0;
    margin: 1.5rem 0.5rem 0.5rem 0.5rem;
    border: none;
    background-color: ${HEADER_BUTTON_LOGIN_COLOR};
`

export {
    ListItemButton,
    LoginName,
    MobileOnly,
    MobileMenuBorder,
    DivLinkList,
    StyledSessionTimer,
    LogOut,
    LogoLink,
    Logo,
    LoginInfo,
    DomainLogo,
    DomainLink,
    DivArrowDown,
    ArrowDownStyled,
    Account,
    Nav,
    StyledListItem,
    Menu,
    LogIn,
    AccountIcon,
    AccountElementsLower,
    StyledLink,
    LinkList,
    StyledLanguageSelector,
    Row,
    RowMobile,
    MenuMobileButton,
    Background,
    Wrap,
    AccountElementsUpper,
    StyledListItemLoginInfo,
}
