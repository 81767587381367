import React, { ReactElement, useState } from 'react'
import i18next from 'i18next'

import { LanguageSwitcherButton, LanguageSwitcherBox } from './languageselector-styled'
import { useCurrentLanguage } from 'hooks'

type languages = 'pl' | 'en'
const availableLanguages: languages[] = ['pl', 'en']

const LanguageSelector = (): ReactElement => {
    const currentLanguage = useCurrentLanguage()
    const [shownLanguage, setShownLanguage] = useState<string>(currentLanguage.toUpperCase())
    const inActiveLanguage = availableLanguages.find((lang) => lang !== currentLanguage) as languages
    const handleLanguageChange = (): void => {
        i18next.changeLanguage(inActiveLanguage)
    }
    return (
        <LanguageSwitcherBox>
            <LanguageSwitcherButton
                type='button'
                title={i18next.getFixedT(inActiveLanguage)('switch-to-language')}
                onClick={handleLanguageChange}
                onMouseOver={() => setShownLanguage(inActiveLanguage.toUpperCase())}
                onMouseLeave={() => setShownLanguage(currentLanguage.toUpperCase())}
            >
                {shownLanguage}
            </LanguageSwitcherButton>
        </LanguageSwitcherBox>
    )
}

export { LanguageSelector }

export default LanguageSelector
