import styled from 'styled-components'
import { HEADER_TEXT_COLOR, ACCENT_TYPEFACE, MOBILE, NOT_MOBILE } from 'global-style'

const LanguageSwitcherBox = styled.div`
    margin: 1.5rem 0.5rem 1rem 1rem;
    padding: 1rem 4rem 1rem 2.25rem;
    width: 6rem;
    text-align: right;
    background: none;
    border: 0;
    border-left: 1px solid ${HEADER_TEXT_COLOR};
    ${NOT_MOBILE} {
        height: 3.5rem;
    }
    ${MOBILE} {
        border-left: none;
        float: left;
        margin: 0;
        padding: 0;
        width: 2rem;
    }
`

const LanguageSwitcherButton = styled.button`
    background: none;
    border: none;
    display: block;
    cursor: pointer;
    margin-top: 0.3rem;
    padding: 0.4rem 0.2rem 0.2rem 0.2rem;
    font-size: 1.25rem;
    line-height: 1rem;
    color: ${HEADER_TEXT_COLOR};
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    ${MOBILE} {
        margin-top: 1.2rem;
        padding: 0;
        line-height: initial;
        width: 100%;
        text-align: right;
    }
`

export { LanguageSwitcherBox, LanguageSwitcherButton }
