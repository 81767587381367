import useAPIQuery, { useAPIQueryWithRefetch, RefetchFunction } from 'hooks/api-query'
import { DomainInformation, DomainState as LocalDomainState } from 'api/domains'

type DomainName = string
export type DomainState = LocalDomainState

export type DomainInformationWithName = { name: DomainName } & DomainInformation

export type RefetchDomainInformation = RefetchFunction

export const useDomainInformation = (name: DomainName): DomainInformation =>
    useAPIQuery<DomainInformation>(`/api/domains/${encodeURIComponent(name)}`)

export const useDomainInformationWithRefetch = (name: DomainName): [DomainInformation, RefetchDomainInformation] =>
    useAPIQueryWithRefetch<DomainInformation>(`/api/domains/${encodeURIComponent(name)}`)

export const useDomains = (names: DomainName[]): DomainInformationWithName[] => {
    return names
        .map((item): [DomainName, DomainInformation | Error | undefined] => [item, useDomainInformation(item)])
        .filter(([, element]) => !(element instanceof Error || element === undefined))
        .map(([name, element]) => ({ name, ...(element as DomainInformation) }))
}

export default useDomainInformation
