import React, { ReactElement, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import {
    Input,
    FormWrapper,
    ErrorMessage,
    AvailableDomainBox,
    RegisterDomainLink,
    Wrapper,
    SearchButton,
    SearchIcon,
    SearchValueInput,
    SearchValueFocusBorder,
    StyledSearchForm,
    Output,
    TextWrapper,
    TextWrapperBold,
    TopThreePrice,
    BestDomains,
    AvailableDomainsTable,
    AvailableDomainsLink,
    WrapperForInput,
    AbsoluteForInput,
    HeightForTextWrapper,
    TextWrapperRow,
    H1,
} from 'components/check-domain-form/check-domain-form-styled'
import { domainSearchRegexp, domainSearchPattern } from 'api/validation'
import { useDomainCheck } from 'hooks/check-domain-form'
import { ImagesPath } from 'paths'
import isValidDomain from 'is-valid-domain'
import { useCalculatePriceDomain } from 'hooks/price-domain'
import Selector from 'components/selector'
import { textFromRoundedPrice } from 'components/text-from-price'

type FormDomainCheck = {
    domain: string
}

const quoteRegexpForBrowser = (regex: string): string => regex.replaceAll('-]', '\\-]')

export const SearchBox = ({ domain }: { domain?: string }): ReactElement => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { register } = useForm<FormDomainCheck>()
    const [domainName, setdomainName] = useState<string>(domain ? domain : '')
    const [isError, setError] = useState(false)
    return (
        <FormWrapper>
            <StyledSearchForm
                onSubmit={(e) => {
                    e.preventDefault()
                    setError(false)
                    navigate(`/check-domain/${domainName.toLowerCase()}`)
                }}
            >
                <SearchIcon src={`${ImagesPath}/search.svg`} alt='' />
                <SearchValueInput
                    type='text'
                    required
                    // Chrome needs to have dash escaped
                    // eslint-disable-next-line: no-useless-escape
                    pattern={quoteRegexpForBrowser(domainSearchPattern)}
                    defaultValue={domain}
                    title={t('check-domain-input-placeholder')}
                    aria-label={t('check-domain-input-placeholder')}
                    placeholder={t('check-domain-input-placeholder')}
                    onInvalid={(e) => {
                        setError(true)
                        e.preventDefault()
                    }}
                    {...register('domain', {
                        required: true,
                        pattern: domainSearchRegexp,
                        onChange: (event) => {
                            setdomainName(event.target.value)
                        },
                        validate: async (value) => {
                            const isLastCharacterDot = value.trim().substr(-1) === '.' ? '' : '.pl'
                            return isValidDomain(`${value}${isLastCharacterDot}`, {
                                subdomain: true,
                                wildcard: true,
                                allowUnicode: true,
                                topLevel: true,
                            })
                        },
                    })}
                />
                <SearchValueFocusBorder />
                <SearchButton type='submit'>{t('check-domain')}</SearchButton>
            </StyledSearchForm>
            {isError && <ErrorMessage>{t('check-domain-invalid')}</ErrorMessage>}
        </FormWrapper>
    )
}
const CalculatePrice = ({ domain }: { domain: string }): ReactElement => {
    const { t } = useTranslation()
    const tenYearsPrice = useCalculatePriceDomain(domain, 10)
    return <>{textFromRoundedPrice(tenYearsPrice, t)}</>
}
const AvailableDomains = ({ data }: { data: string[] }): ReactElement => {
    const { t } = useTranslation()
    const topThree = data.slice(0, 3)
    const domain = data[0].split('.')[0]
    const [scrolledTo, setScrolledTo] = useState<number>(0)
    const updateTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const itemsElement = useRef<HTMLTableSectionElement>(null)
    const scrollTo = (index: number): void => {
        if (itemsElement.current === null) {
            return
        }
        const elementWidth = (itemsElement.current.scrollWidth - (data.length - 1) * 32.0) / Math.max(data.length, 1)
        itemsElement.current.scrollLeft = elementWidth * index + index * 32
    }
    const scrolled = (): void => {
        console
        if (itemsElement.current === null) {
            return
        }
        const elementWidth = (itemsElement.current.scrollWidth - (data.length - 1) * 32) / Math.max(data.length, 1)
        const offset = itemsElement.current.scrollLeft
        const basePoint = scrolledTo * elementWidth + scrolledTo * 32
        const newItem = Math.floor((offset + 0.25 * elementWidth) / elementWidth)
        if (offset < basePoint - elementWidth * 0.5 || offset > basePoint + elementWidth - 1 - elementWidth * 0.5) {
            if (updateTimer.current !== null) {
                clearTimeout(updateTimer.current)
            }
            updateTimer.current = setTimeout(() => {
                setScrolledTo(newItem)
                updateTimer.current = null
            }, 100)
        }
    }
    const TextWithDiffrentWeight = (domain: string): ReactElement => {
        const [first, ...rest] = decodeURIComponent(domain).split('.')
        return domain.length > 20 ? (
            <HeightForTextWrapper>
                <TextWrapper>{first}</TextWrapper>
                {rest.length > 0 && <TextWrapperBold>{['', ...rest].join('\u200b.')}</TextWrapperBold>}
            </HeightForTextWrapper>
        ) : (
            <HeightForTextWrapper>
                <TextWrapperRow>
                    {first}
                    {rest.length > 0 && <TextWrapperBold>{['', ...rest].join('\u200b.')}</TextWrapperBold>}
                </TextWrapperRow>
            </HeightForTextWrapper>
        )
    }
    return (
        <Wrapper>
            <H1 text={t('header-check-domain', { domain: domain })}>
                <Trans i18nKey='domain-wrapped' values={{ domain }} components={{ 1: <strong /> }}>
                    Oferty dla
                    {`<1>{{ domain }}</1>`}
                </Trans>
            </H1>
            <BestDomains>
                {topThree.map((domain) => (
                    <AvailableDomainBox key={domain}>
                        {TextWithDiffrentWeight(domain)}
                        <TopThreePrice>
                            <CalculatePrice domain={domain} />
                        </TopThreePrice>
                        <RegisterDomainLink to={`/register-domain/${encodeURIComponent(domain)}`}>
                            {t('available-domain-purchase')}
                        </RegisterDomainLink>
                    </AvailableDomainBox>
                ))}
            </BestDomains>
            <AvailableDomainsTable>
                <thead>
                    <tr>
                        <th scope={'col'} key={'domain'}>
                            {t('domain-pagecheck')}
                        </th>
                        <th scope={'col'} key={'price'}>
                            {t('price-pagecheck')}
                        </th>
                        <td></td>
                    </tr>
                </thead>
                <tbody onScroll={scrolled} ref={itemsElement}>
                    {data.map((domain) => (
                        <tr key={domain}>
                            <td data-column-name={t('domain-pagecheck')} key={'domain ' + domain}>
                                {domain}
                            </td>
                            <td data-column-name={t('price-pagecheck')} key={'price ' + domain}>
                                <CalculatePrice domain={domain} />
                            </td>
                            <td key={`register ` + domain}>
                                <AvailableDomainsLink to={`/register-domain/${encodeURIComponent(domain)}`}>
                                    {t('available-domain-purchase')}
                                </AvailableDomainsLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </AvailableDomainsTable>
            <Selector data={data} scrollTo={scrollTo} scrolledTo={scrolledTo} />
        </Wrapper>
    )
}

const Result = ({ domain }: { domain: string }): ReactElement => {
    const { t } = useTranslation()
    const {
        formState: { errors },
    } = useForm<FormDomainCheck>()
    const validationError = errors.domain
    const data = useDomainCheck(domain)
    return (
        <Output>
            {validationError ? (
                validationError.type === 'required' ? (
                    <ErrorMessage>{t('check-domain-required')}</ErrorMessage>
                ) : (
                    <ErrorMessage>{t('check-domain-invalid')}</ErrorMessage>
                )
            ) : null}
            {data && data.length > 0 ? <AvailableDomains data={data} /> : null}
            {data && data.length === 0 ? <ErrorMessage>{t('check-domain-not-found')}</ErrorMessage> : null}
        </Output>
    )
}

const CheckDomainForm = ({ domain }: { domain?: string }): ReactElement => {
    return (
        <WrapperForInput>
            <AbsoluteForInput>
                <Input>
                    <SearchBox domain={domain} />
                </Input>
            </AbsoluteForInput>
        </WrapperForInput>
    )
}

export { CheckDomainForm, Result }
