import { resources } from 'i18n'
import getQueryParam from 'api/query'

function getLocalStoreKey(key: string): string | null {
    return window && window.localStorage && window.localStorage.getItem && window.localStorage.getItem(key)
}

function getLang(): string {
    const lang = getQueryParam('lng') || getLocalStoreKey('i18nextLng') || 'en'
    return lang
}

function getResource(lang: string, key: string): string | null {
    const data = Object.entries(resources).find(([k]) => k === lang)
    if (!data) return null
    const entry = Object.entries(data[1].main).find(([k]) => k === key)
    return entry ? entry[1] : null
}

function getLoadingText(): string {
    const lang = getLang()
    const text = getResource(lang, 'loading') || resources.en.main.loading
    return text
}

function translateWindowTitle(): void {
    const lang = getLang()
    const title = getResource(lang, 'page-title') || resources.en['main']['page-title']
    document.title = title
}

export default getLoadingText
export { translateWindowTitle }
