import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const dnsPhone = '+48 58 347 18 80'
const dnsEmail = 'dns@task.gda.pl'
const taskNIP = '584-020-35-93'
const taskREGON = '000001620'
const taskUrl = 'https://task.gda.pl'
const accountDataBank = 'Santander Bank Polska S.A'
const accountDataBankNumber = '41 1090 1098 0000 0000 0901 5569'

const resources = {
    en: {
        main: {
            loading: 'Loading...',
            'page-title': 'Network services panel - CI TASK',
            'header-home': 'Network services panel',
            'header-domains': 'Domains',
            'header-domain': 'Domain',
            'header-register': 'Registration of {{domain}} domain',
            'header-register-formatted': 'Registration of <strong>{{domain}}</strong> domain',
            'header-check-domain': 'Availability check for domain {{domain}}',
            'header-after-register': 'Domain registration completed',
            'header-price': 'Domain price',
            'header-summary': 'Summary',
            'header-registration-rules': 'Registration rules',
            'header-price-list': 'Price list',
            'header-faq': 'Frequently asked questions',

            'nav-home': 'Main page',
            'nav-task': 'CI TASK main page',
            'nav-domains': 'My domains',
            'nav-logout': 'Log out',
            'nav-account': 'Account',
            'nav-register-domain': 'Register domain',
            'nav-menu-caption': 'MENU',
            'nav-domain-check': 'Register domain',
            'nav-contact': 'CONTACT',
            'nav-contact-tel': dnsPhone,
            'nav-contact-mail': dnsEmail,
            'nav-price-list': 'Prices',
            'nav-registration-rules': 'Registration Rules',
            'nav-faq': 'FAQ',
            'nav-session': 'Renew session',

            'navigation-link-continue': 'Continue',
            'navigation-link-return': 'Return',
            'login-info': 'Logged as: ',
            'login-info-session-time': 'Time till the end of session: ',

            'switch-to-language': 'Switch to language: EN',
            'error-occured': 'An error occured while loading the page',
            'error-contact': 'Please contact the site administrator.',
            'error-network': 'Network error: {{status}} - {{text}}',
            'error-access': 'Access error: {{text}}',
            'error-application': 'Application error: {{text}}',
            'error-not-found': 'Not found: {{text}}',
            'error-inconsistent-data':
                `Inconsistency in copied data. Please contact administrator` + `via e-mail ${dnsEmail}`,

            'nav-login': 'Log in',
            'change-language': 'PL',
            availability: 'Available',
            'available-domain-purchase': 'Buy domain',
            'check-domain-input-placeholder': 'enter domain name',

            'logo-viewbox-width': '248',

            'check-domain-error': 'Application error',
            'check-domain-available': 'Domain is avaiable',
            'check-domain-not-available': 'Domain is not avaiable',
            'check-domain-available-list': 'Avaiable list of domains :',
            'check-domain-not-found': 'No domains with the given name were found',
            'check-domain-required': 'Domain name is required',
            'check-domain-invalid': 'Please enter a valid domain name.',

            'domain-details': 'Details',
            'domain-owner': 'Owner',
            'domain-buyer': 'Buyer',
            'domain-payer': 'Payer',
            'domain-registry': 'Registry',
            'domain-records': 'DNS records',
            'domain-invoice-receiver': 'Invoice receiver',
            'domain-invoice-receiver-name': 'Invoice receiver',
            'domain-invoice-receiver-persons': 'Persons',
            'domain-dnssec-keytag': 'DNSSEC key tag',
            'domain-ns-servers': 'NS Servers',
            'domain-info-expires': 'Expires',
            'domain-info-expired': 'Expired',
            'domain-days-to-expiration': 'Days to expiration',
            'domain-info-valid-with-days_zero': '{{ date }} (in less than a day)',
            'domain-info-valid-with-days_one': '{{ date }} (in {{ count }} day)',
            'domain-info-valid-with-days': '{{ date }} (in {{ count }} days)',
            'domain-info-nearly-expired-with-days_zero': '{{ date }} (in less than a day)',
            'domain-info-nearly-expired-with-days_one': '{{ date }} (in {{ count }} day)',
            'domain-info-nearly-expired-with-days': '{{ date }} (in {{ count }} days)',
            'domain-info-expired-with-days': '{{ date }} (expired)',
            'domain-info-valid-indefinitely': 'Valid indefinitely',
            'domain-info-registered': 'Registered',
            'domain-info-modify': 'Last modified',
            'back-to-list': 'back to list of domains',

            'domain-record-new': 'New record',
            'domain-record-edited': 'Edited record',
            'domain-record-remove': 'Remove',
            'domain-record-move-up': 'Move up',
            'domain-record-move-down': 'Move down',
            'domain-records-edit': 'Edit',
            'domain-records-save': 'Save',
            'domain-records-cancel': 'Cancel',
            'domain-records-errors': 'Marked domain names are incorrect',
            'domain-records-dialog-message':
                'Are you sure you want to continue?<br/>' +
                'Providing incorrect NS servers will cause the domain to misbehave.<br/>' +
                'It will take up to 24h for the changes to come into effect.',
            'domain-records-dialog-old': 'Current entries',
            'domain-records-dialog-new': 'New entries',
            'domain-records-dialog-ok': 'Apply changes',
            'domain-records-dialog-cancel': 'Discard changes',

            'domain-owner-name': 'Owner',
            'domain-owner-persons': 'Persons',
            'domain-payer-name': 'Payer',
            'domain-payer-persons': 'Persons',
            'domain-buyer-name': 'Buyer',
            'domain-buyer-persons': 'Persons',

            'domain-address': 'Address',
            'domain-price': 'Price',
            'domain-addressfc': 'Mailing address',
            'domain-nip': 'NIP',
            'domain-regon': 'REGON',
            'domain-krs': 'KRS',
            'domain-phone': 'Phone',
            'domain-email': 'E-mail',
            'domain-name': 'Name',
            'domain-firstname': 'Name',
            'domain-surname': 'Surname',
            'domain-roles': 'Roles',
            'domain-additional-information': 'More info',
            'domain-in-update-notification': 'Records are being updated',
            'domain-in-update-tooltip': 'Records cannot be modified while being updated',

            'domain-state': 'State',
            'domain-state-new': 'Reserved',
            'domain-state-active': 'Active',
            'domain-state-blocked': 'Blocked',
            'domain-state-expired': 'Expired',
            'domain-state-ns-update': 'During update',
            'domain-state-ending': 'Nearly expired',
            'domain-state-in-queue': 'Awaiting',

            'price-list': 'Domain registration prices',
            'price-domains-task': 'Domains operated by CI TASK',
            'price-registration-explanation':
                'Domain registration prices in PLN (inluding VAT). \
                The presented prices are not final and are subject to change.',
            'price-list-not-ready': 'Loading current price list...',
            'price-list-type-TASKtask': 'Domains administred by TASK',
            'price-list-type-NASKfunc': 'Functional domain administred by NASK',
            'price-list-type-NASKregio': 'Regional domains administred by NASK',
            'price-list-type-NASKpl': 'pl domains',
            'price-list-type-NASKedu': 'edu.pl domains',
            'price-individual': 'Price negotiated individually',
            'footer-additional': 'Additional information',

            'address-value': 'G. Narutowicza 11/12 Street',
            'addresspostal-value': '80-233 Gdańsk',

            phone: 'phone',
            'phone-value': dnsPhone,
            email: 'e-mail',
            'email-value': dnsEmail,
            nip: 'NIP',
            'nip-value': taskNIP,
            regon: 'REGON',
            'regon-value': taskREGON,
            'url-value': `${taskUrl}/en/`,
            'copyright-text': 'Centre of Informatics - Tricity Academic Supercomputer & Network',
            'footer-ad': 'Your new <1>domain</1> at your fingertips!',
            'footer-links': 'Additional resources',
            'footer-links-contact': 'Full contact details',
            'footer-links-network': 'CI TASK Network',
            'footer-links-about': 'About CI TASK',
            'check-domain-title': 'Domain name which availability will be checked',
            contact: 'Contact',
            'contact-info':
                'If you have any additional questions regarding domain registration please contact ' +
                'DNS Administration Team.',

            'main-ad-header': 'Domain service',
            'main-ad-text': 'edu.pl, gda.pl, pl and others',
            'top-three-price': 'from {{ price }}/year',

            'session-left': 'Time till session ends. Click to refresh session.',
            'session-left-seconds': '{{seconds}} sec.',
            'session-left-minutes': '{{minutes}} min.',
            'session-left-over-minutes': '{{minutes}}+ min.',

            'registration-rules-agree': 'I agree to the registration rules ',

            'period-registration': 'For period',
            'type-registration': 'Choose registration data source',
            'type-registration-get-data': 'Get registration data from previously registered domain',
            'type-registration-form': 'Complete the form',
            'data-registration': 'Registration Data',
            'period-reg': 'Period',
            'summary-finish': 'Purchase',
            'domain-pagecheck': 'Domain',
            'availability-pagecheck': 'Availability',
            'price-pagecheck': 'Price',

            'register-button': 'Register',
            'registration-year_one': '{{ count }} year',
            'registration-year_other': '{{ count }} years',
            'registration-domain-name': 'Domain name: ',
            'registration-company-name': 'Company Name: ',
            'registration-owner-data': 'Owner registration data',
            'registration-agreement': 'I will be getting electronic invoice ',
            'registration-billing': 'Billing: ',
            'registration-billing-address': 'Billing address',
            'registration-DNS': 'Name Servers: DNS',
            'registration-host-name': 'Host name',
            'registration-IP-address': 'IP address: ',
            'registration-secondary-host-name': 'Secondary host name: ',
            'registration-secondary-IP-address': 'Secondary IP address: ',
            'registration-third-host-name': 'Third host name: ',
            'registration-third-IP-address': 'Third IP address: ',
            'registration-company': 'Company',
            'registration-person': 'Private person',
            'registration-administration': 'Administration',
            'registration-register-domain': 'Register domain',
            'registration-invoice': 'I will be getting electronic invoice',
            'registration-invoice-email': 'Email for invoice',
            'registration-DNS-possession': 'I am in possession of DNS',
            'registration-send-data': 'Send',
            'registration-owner-company': 'Owner - company',
            'registration-owner-person': 'Owner - person',
            'registration-other-company': 'Other company',
            'registration-email-invoice': 'Address to send invoice (Fill only if differs from payer address)',

            'regform-price-value': '{{value}}',

            'regform-step-number': 'Step <strong>{{stepNumber}}</strong>/{{totalSteps}}: {{title}}',
            'regform-data-step': 'Registration details',
            'regform-summary-step': 'Data summary',
            'regform-payment-step': 'Payment',
            'regform-finish-step': 'Registration summary',
            'regform-basic-data': 'Basic data',
            'regform-billing-data': 'Billing data',
            'on-invalid': 'Name entered is invalid',

            'regform-domain-name': 'Domain name',
            'regform-price-with-tax': 'Price (incl. tax)',
            'regform-payment-method': 'Payment method',
            'regform-payment-method-value': 'Bank transfer',

            'regform-source-domain': 'Copy information from registered domain',
            'regform-city': 'City/town',
            'regform-street': 'Street',
            'regform-street-number': 'Street house/apartment',
            'regform-municipality': 'Municipality',
            'regform-district': 'District',
            'regform-province': 'Province',
            'regform-country': 'Country',
            'regform-zip': 'Postal code',
            'regform-NIP': 'Tax ID',
            'regform-REGON': 'REGON',
            'regform-firstname': 'First name',
            'regform-surname': 'Surname',
            'regform-name': 'Name',
            'regform-phone': 'Phone',
            'regform-email': 'E-mail',
            'regform-address': 'Address',
            'regform-addressfc': 'Address for correspondence',
            'regform-owner': 'Owner details',
            'regform-buyer': 'Buyer details',
            'regform-payer': 'Payer details',
            'regform-invoice-receiver': 'Invoice receiver details',
            'regform-address-owner': 'Owner address',
            'regform-address-buyer': 'Buyer address',
            'regform-address-payer': 'Payer address',
            'regform-private-or-company-owner': 'Owner type',
            'regform-private-or-company-buyer': 'Buyer type',
            'regform-private-or-company-payer': 'Payer type',
            'regform-private-or-company-invoice-receiver': 'Invoice receiver type',
            'regform-private': 'Person',
            'regform-company': 'Company',
            'regform-yes': 'Yes',
            'regform-no': 'No',
            'regform-reuse-data': 'Copy information from already registered domain',
            'regform-different-payer': 'Provide different payer data',
            'regform-different-owner': 'Provide different owner data',
            'regform-different-invoice-receiver': 'Provide different invoice receiver data',
            'regform-different-addressfc': 'Provide different address to send invoice: ',
            'regform-additional-emails': 'Emails to send invoice',
            'regform-additional-email': 'Additional e-mail',
            'regform-additional-email-button': 'Add e-mail',
            'regform-domain': 'Domain',
            'regform-period': 'Duration of the agreement (years)',
            'regform-copied-from': 'Registration data copied from domain',
            'regform-consent': 'I agree to the <1>registration rules</1>',
            'regform-same-as-buyer': 'Same as buyer',
            'regform-same-address-as-buer': 'Same as buyer address',
            'regform-same-address-as-payer': 'Same as payer address',

            'regform-cancel': 'Cancel registration',
            'regform-commit': 'Order with an obligation to pay',
            'regform-back': 'Previous step',
            'regform-forward': 'Next step',
            'regform-back-to-main': 'Back to main page',

            'regform-error-bad-city-code': 'Unknown city',
            'regform-error-bad-street-code': 'Unknown street',
            'regform-error-bad-street-number': 'Only latin letters, numbers, slash, dash, dot and space allowed',
            'regform-error-consent-required': 'Consent is required to proceed',
            'regform-error-broken-form': 'Broken form data!',
            'regform-error-domain-taken': 'Domain is already registered',
            'regform-error-send-form-failed': 'Network error',
            'regform-error-invalid-nip-format': 'Invalid format: expected 10 consecutive digits',
            'regform-error-invalid-nip-checksum': 'Invalid value',
            'regform-error-unknown-nip': 'Tax id not found in REGON database',
            'regform-error-there-were-errors': 'There are errors in the form',
            'regform-error-bad-zip-code':
                'Postal code must have five digits, with first two separated by dash, eg. 12-345',
            'regform-error-invalid-phone': 'Invalid phone number',
            'regform-error-invalid-email': 'Invalid e-mail',
            'regform-error-invalid-value': 'Invalid value',
            'regform-error-invalid-text': 'This field cannot end or begin with whitespaces and cannot be empty',
            'regform-error-invalid-firstname':
                'Surname must contain at least two characters and cannot end or begin with whitespaces',
            'regform-error-invalid-surname':
                'Surname must contain at least two characters and cannot end or begin with whitespaces',
            'regform-error-invalid-name':
                'Name must contain at least three characters and cannot end or begin with whitespaces',
            'regform-error-backend-unsupported_value': 'This registration period is not allowed',
            'regform-error-backend-teryt': 'Unrecognized TERYT code',
            'regform-error-backend-correspondence_address_set_incorrectly': 'Correspondence address set in a wrong way',
            'regform-error-backend-str.regex': 'Invalid value format',
            'regform-error-backend-any_str.min_length': 'Value too short',
            'regform-error-backend-any_str.max_length': 'Value too long',
            'regform-error-backend-email': 'Invalid value',
            'regform-error-backend-regon':
                `Company data does not match the data provided by the REGON database. ` +
                `Please contact via e-mail ${dnsEmail}.`,
            'regform-error-backend-inconsistent_with_database':
                `Company data does not match the data provided by the REGON database. ` +
                `Please contact via e-mail ${dnsEmail}.`,
            'regform-error-backend-empty_not_allowed': 'Cannot be empty',

            'regform-toggle-city-list': 'Toggle city suggestions list',
            'regform-toggle-street-list': 'Toggle street suggestions list',
            'regform-confirmation': 'Confirmation',
            'regform-success': 'The domain has been registered.',
            'regform-thanks':
                'Thank you for registering domain. In your e-mail inbox you will find a confirmation letter ' +
                'containing payment details.',
            'regform-payment-header': 'Make a payment',
            'regform-payment-account-info':
                'In order to complete registration, please make a bank transfer ' +
                'for <strong>{{amount}}</strong> to the following bank account:<br/>' +
                'Gdańsk Technical University of Technology, CI TASK <br />' +
                '11/12 Narutowicza Street, 80-233 Gdańsk <br />' +
                `${accountDataBank}<br />` +
                `<strong>${accountDataBankNumber}</strong>` +
                '<br/>Transfer title: <strong>domain {{domain}}</strong>',

            'province-short': 'pr.',
            'municipality-short': 'mun.',

            'check-domain': 'Check',
            'is-available': 'Checking availability of a domain',
            'is-available-description':
                'Form is checking the availability of domain such as: gda.pl, gdansk.pl, gdynia.pl, sopot.pl i med.pl.',
            'my-domains': 'My domains',
            'registration-rules': 'CI TASK domain registration rules',

            'navigation section': 'Navigation menu',
            'footer section': 'Additional information (footer)',
            'jump to': 'Skip to {{where}}',

            'toggle-sort-button': 'Toggle sorting by {{column}}',
            'scroll-list-to': 'Scroll to {{domain}}',

            'sorted-ascending': 'sorted ascending',
            'sorted-descending': 'sorted descending',

            'mobile-menu': 'Navigation menu',
            'toggle-account-menu': 'Toggle visibility of account menu',

            faq: 'Frequently asked questions',
            'domain-wrapped': 'Available domains for\u00a0<strong>{{domain}}</strong>',
        },
    },
    pl: {
        main: {
            loading: 'Ładowanie...',
            'page-title': 'Panel usług sieciowych - CI TASK',
            'header-home': 'Panel usług sieciowych',
            'header-domains': 'Domeny',
            'header-domain': 'Domena',
            'header-register': 'Rejestracja domeny {{domain}}',
            'header-register-formatted': 'Rejestracja domeny <strong>{{domain}}</strong>',
            'header-check-domain': 'Sprawdzenie dostępności domeny {{domain}}',
            'header-after-register': 'Rejestracja domeny zakończona',
            'header-price': 'Cena domeny',
            'header-summary': 'Podsumowanie',
            'header-registration-rules': 'Regulamin',
            'header-price-list': 'Cennik',
            'header-faq': 'Najczęściej zadawane pytania',
            'nav-home': 'Strona główna',
            'nav-task': 'Strona główna CI TASK',
            'nav-domains': 'Moje domeny',
            'nav-login': 'Zaloguj się',
            'nav-menu-caption': 'MENU',
            'nav-contact': 'KONTAKT',
            'nav-domain-check': 'Zarejestruj domenę',
            'nav-contact-tel': dnsPhone,
            'nav-contact-mail': dnsEmail,
            'nav-price-list': 'Cennik',
            'nav-registration-rules': 'Regulamin',
            'nav-logout': 'Wyloguj się',
            'nav-account': 'Konto',
            'nav-register-domain': 'Rejestracja domeny',
            'nav-faq': 'FAQ',
            'nav-session': 'Odśwież sesję',

            'navigation-link-continue': 'Kontynuuj',
            'navigation-link-return': 'Powrót',
            'login-info': 'Zalogowano jako: ',
            'login-info-session-time': 'Czas pozostały do końca sesji: ',
            'change-language': 'EN',

            'switch-to-language': 'Przełączenie języka: PL',
            'error-occured': 'Wystąpił błąd podczas wczytywania strony',
            'error-contact': 'Prosimy o kontakt z administratorem strony.',
            'error-network': 'Błąd sieci: {{status}} - {{text}}',
            'error-access': 'Błąd dostępu: {{text}}',
            'error-application': 'Błąd aplikacji: {{text}}',
            'error-not-found': 'Nie znaleziono: {{text}}',
            'error-inconsistent-data':
                `Błąd spójności danych w skopiowanej domenie. Prosimy o kontakt na ` + `podany email: ${dnsEmail}`,

            'logo-viewbox-width': '195.24077',

            'check-domain-error': 'Błąd aplikacji',
            'check-domain-available': 'Domena jest dostepna',
            'check-domain-not-available': 'Domena jest niedostępna',
            'check-domain-available-list': 'Lista dostepnych domen :',
            'check-domain-not-found': 'Nie znaleziono domen o podanej nazwie',
            'check-domain-required': 'Nazwa domeny jest wymagana.',
            'check-domain-invalid': 'Niepoprawna nazwa domeny.',
            'top-three-price': 'od {{ price }}/rok',
            availability: 'Dostępna',
            'on-invalid': 'Wprowadzona nazwa jest niepoprawna',
            'check-domain-input-placeholder': 'wpisz nazwę domeny',

            'domain-details': 'Szczegóły',
            'domain-price': 'Cena',
            'domain-owner': 'Właściciel',
            'domain-buyer': 'Nabywca',
            'domain-payer': 'Płatnik',
            'domain-invoice-receiver': 'Odbiorca faktury',
            'domain-invoice-receiver-name': 'Odbiorca faktury',
            'domain-invoice-receiver-persons': 'Osoby',
            'domain-registry': 'Rejestr',
            'domain-records': 'Rekordy DNS',
            'domain-dnssec-keytag': 'DNSSEC: znacznik klucza',
            'domain-dnssec-alg': 'DNSSEC: algorytm',
            'domain-dnssec-digesttype': 'DNSSEC: typ skrótu',
            'domain-ns-servers': 'Serwery NS',
            'domain-info-expires': 'Wygasa',
            'domain-info-expired': 'Wygasła',
            'domain-days-to-expiration': 'Dni do wygaśnięcia',
            'domain-info-registered': 'Zarejestrowano',
            'domain-info-valid-with-days_zero': '{{ date }} (za niecały dzień)',
            'domain-info-valid-with-days_one': '{{ date }} (za {{ count }} dzień)',
            'domain-info-valid-with-days': '{{ date }} (za {{ count }} dni)',
            'domain-info-nearly-expired-with-days_zero': '{{ date }} (za niecały dzień)',
            'domain-info-nearly-expired-with-days_one': '{{ date }} (za {{ count }} dzień)',
            'domain-info-nearly-expired-with-days': '{{ date }} (za {{ count }} dni)',
            'domain-info-expired-with-days': '{{ date }} (wygasła)',
            'domain-info-valid-indefinitely': 'Ważna bezterminowo',
            'domain-info-modify': 'Ostatnio zmodyfikowano',
            'back-to-list': 'powrót do listy domen',

            'domain-record-new': 'Nowy rekord',
            'domain-record-edited': 'Edytowany rekord',
            'domain-record-remove': 'Usuń',
            'domain-record-move-up': 'Przenieś wyżej',
            'domain-record-move-down': 'Przenieś wyżej',
            'domain-records-edit': 'Zmień',
            'domain-records-save': 'Zapisz',
            'domain-records-cancel': 'Anuluj',
            'domain-records-errors': 'Wyróżnione nazwy domen są niepoprawne',
            'domain-records-dialog-message':
                'Czy jesteś pewny, że chcesz kontynuować?<br/>' +
                'Zmiana serwerów NS na błędne może spowodować niepoprawne działanie domeny.<br/>' +
                'Wprowadzone zmiany będą widoczne do 24h.',
            'domain-records-dialog-old': 'Bieżące wpisy',
            'domain-records-dialog-new': 'Nowe wpisy',
            'domain-records-dialog-ok': 'Zatwierdź zmiany',
            'domain-records-dialog-cancel': 'Odrzuć zmiany',

            'domain-dnssec-digest': 'DNSSEC: skrót',
            'domain-owner-name': 'Właściciel',
            'domain-owner-persons': 'Osoby',
            'domain-payer-name': 'Płatnik',
            'domain-payer-persons': 'Osoby',
            'domain-buyer-name': 'Nabywca',
            'domain-buyer-persons': 'Osoby',

            'domain-address': 'Adres',
            'domain-addressfc': 'Adres do korespondencji',
            'domain-nip': 'NIP',
            'domain-regon': 'REGON',
            'domain-krs': 'KRS',
            'domain-phone': 'Telefon',
            'domain-email': 'E-mail',
            'domain-name': 'Nazwa',
            'domain-firstname': 'Imię',
            'domain-surname': 'Nazwisko',
            'domain-roles': 'Role',
            'domain-additional-information': 'Szczegóły',
            'domain-in-update-notification': 'Wpisy w trakcie aktualizacji',
            'domain-in-update-tooltip': 'W trakcie aktualizacji wpisów nie ma możliwości ich edycji',

            'domain-state': 'Status',
            'domain-state-new': 'Zarezerwowana',
            'domain-state-active': 'Aktywna',
            'domain-state-blocked': 'Zablokowana',
            'domain-state-expired': 'Wygasła',
            'domain-state-ns-update': 'Aktualizowana',
            'domain-state-ending': 'Wygasająca',
            'domain-state-in-queue': 'Oczekująca',

            'price-list': 'Cennik rejestracji domen',
            'price-domains-task': 'Domeny obsługiwane przez CI TASK',
            'price-registration-explanation':
                'Ceny rejestracji i przedłużenia domen w PLN (brutto). \
                Podane ceny nie są ostateczne i mogą ulec zmianie.',
            'price-list-not-ready': 'Wczytywanie aktualnego cennika...',
            'price-list-type-TASKtask': 'Domeny TASK',
            'price-list-type-NASKfunc': 'Domeny funkcjonalne NASK',
            'price-list-type-NASKregio': 'Domeny regionalne NASK',
            'price-list-type-NASKpl': 'Domeny PL',
            'price-list-type-NASKedu': 'Domeny edu.pl',
            'price-individual': 'Cena negocjowana indywidualnie',
            'address-value': 'ul. Narutowicza 11/12',
            'addresspostal-value': '80-233 Gdańsk',

            phone: 'tel.',
            'phone-value': dnsPhone,
            email: 'e-mail',
            'email-value': dnsEmail,
            nip: 'NIP',
            'nip-value': taskNIP,
            regon: 'REGON',
            'regon-value': taskREGON,
            'url-value': taskUrl,
            'copyright-text': 'Centrum Informatyczne Trójmiejskiej Akademickiej Sieci Komputerowej',
            'footer-ad': 'Nie czekaj, zarejestruj <1>domenę</1> już teraz!',
            'footer-links': 'Przydatne linki',
            'footer-links-contact': 'Pełne dane kontaktowe',
            'footer-links-network': 'Sieć CI TASK',
            'footer-links-about': 'O CI TASK',
            'check-domain-title': 'Nazwa domeny, której dostępność ma być sprawdzona',
            contact: 'Kontakt',
            'contact-info':
                'Wszelkie pytania dotyczące rejestracji domen oraz obsługi DNS należy kierować do ' +
                'Zespołu Administratorów DNS.',

            'main-ad-header': 'Obsługa domen',
            'main-ad-text': 'edu.pl, gda.pl, pl, oraz innych',

            'session-left': 'Czas pozostały do końca sesji. Kliknij, żeby odświeżyć sesję.',
            'session-left-seconds': '{{seconds}} s',
            'session-left-minutes': '{{minutes}} min',
            'session-left-over-minutes': '{{minutes}}+ min',

            'period-registration': 'Na okres',
            'type-registration': 'Wybierz sposób rejestracji',
            'type-registration-get-data': 'Pobierz dane z zarejestrowanej domeny',
            'type-registration-form': 'Uzupełnij formularz',
            'data-registration': 'Dane rejestracji ',
            'period-reg': 'Okres',
            'available-domain-purchase': 'Kup domenę',
            'domain-pagecheck': 'Domena',
            'availability-pagecheck': 'Dostępność',
            'price-pagecheck': 'Cena ',

            'registration-domain-name': 'Nazwa domeny: ',
            'registration-company-name': 'Nazwa firmy: ',
            'registration-owner-data': 'Dane właściciela (dysponenta) domeny',
            'registration-billing': 'Dane do faktury (płatnik)',
            'registration-agreement': 'Wyrażam zgodę na wysyłkę faktur drogą elektroniczną na adres: ',
            'registration-billing-address':
                'Adres do przesyłania faktur VAT (Wypełnić jeśli jest inny niż adres płatnika)',
            'registration-DNS': 'Serwery nazw: DNS',
            'registration-host-name': 'Nazwa hosta',
            'registration-IP-address': 'Adres IP: ',
            'registration-secondary-host-name': 'Nazwa drugiego hosta: ',
            'registration-secondary-IP-address': 'IP drugiego hosta: ',
            'registration-third-host-name': 'Nazwa trzeciego hosta: ',
            'registration-third-IP-address': 'IP trzeciego hosta: ',
            'registration-rules-agree': 'Zgadzam się z zasadami rejestracji domen w CI TASK ',

            'register-button': 'Zarejestruj',
            'registration-year_one': '{{ count }} rok',
            'registration-year_few': '{{ count }} lata',
            'registration-year_many': '{{ count }} lat',
            'registration-company': 'Firma',
            'registration-person': 'Osoba prywatna',
            'registration-administration': 'Administracja',
            'registration-register-domain': 'Zarejestruj domenę',
            'registration-invoice': 'Chcę otrzymywać fakturę drogą elektroniczną',
            'registration-invoice-email': 'Email na fakturę',
            'registration-DNS-possession': 'Posiadam serwery nazw DNS',
            'registration-send-data': 'Wyślij',
            'registration-owner-company': 'Właściciel domeny - firma',
            'registration-owner-person': 'Właściciel domeny - osoba fizyczna',
            'registration-other-company': 'Inna firma',
            'registration-email-invoice':
                'Adres do przesyłania faktur VAT (Wypełnić jeśli jest inny niż adres płatnika)',

            'regform-price-value': '{{value}}',

            'regform-step-number': 'Krok <strong>{{stepNumber}}</strong>/{{totalSteps}}: {{title}}',
            'regform-data-step': 'Szczegóły rejestracji',
            'regform-summary-step': 'Podsumowanie danych',
            'regform-payment-step': 'Płatność',
            'regform-finish-step': 'Podsumowanie rejestracji',
            'regform-basic-data': 'Podstawowe dane',
            'regform-billing-data': 'Dane rozliczeniowe',

            'regform-domain-name': 'Nazwa domeny',
            'regform-price-with-tax': 'Cena z VAT',
            'regform-payment-method': 'Metoda płatności',
            'regform-payment-method-value': 'Przelew na konto',

            'regform-source-domain': 'Domena do skopiowania danych',
            'regform-city': 'Miasto',
            'regform-street': 'Ulica',
            'regform-street-number': 'Numer domu/mieszkania',
            'regform-municipality': 'Gmina',
            'regform-district': 'Powiat',
            'regform-province': 'Województwo',
            'regform-country': 'Kraj',
            'regform-zip': 'Kod pocztowy',
            'regform-NIP': 'NIP',
            'regform-REGON': 'REGON',
            'regform-firstname': 'Imię',
            'regform-surname': 'Nazwisko',
            'regform-name': 'Nazwa',
            'regform-phone': 'Telefon',
            'regform-email': 'E-mail',
            'regform-address': 'Adres',
            'regform-addressfc': 'Adres do koresponencji',
            'regform-owner': 'Dane właściciela',
            'regform-buyer': 'Dane nabywcy',
            'regform-payer': 'Dane płatnika',
            'regform-invoice-receiver': 'Dane odbiorcy faktury',
            'regform-address-owner': 'Adres właściciela',
            'regform-address-buyer': 'Adres nabywcy',
            'regform-address-payer': 'Adres płatnika',
            'regform-private-or-company-owner': 'Typ właściciela',
            'regform-private-or-company-buyer': 'Typ nabywcy',
            'regform-private-or-company-payer': 'Typ płatnika',
            'regform-private-or-company-invoice-receiver': 'Typ odbiorcy faktury',
            'regform-private': 'Osoba prywatna',
            'regform-company': 'Firma',
            'regform-yes': 'Tak',
            'regform-no': 'Nie',
            'regform-reuse-data': 'Skopiuj dane z już zarejestrowanej domeny',
            'regform-different-payer': 'Płatnik inny niż nabywca',
            'regform-different-owner': 'Właściciel inny niż nabywca',
            'regform-different-invoice-receiver': 'Odbiorca faktury inny niż nabywca',
            'regform-different-addressfc': 'Inny adres do korespondencji',
            'regform-additional-emails': 'Adresy email, na które wysłana będzie elektroniczna faktura',
            'regform-additional-email': 'Dodatkowy e-mail',
            'regform-additional-email-button': 'Dodaj e-mail',
            'regform-domain': 'Domena',
            'regform-period': 'Czas trwania umowy (lata)',
            'regform-copied-from': 'Dane skopiowane z domeny',
            'regform-consent': 'Zgadzam się z <1>zasadami rejestracji</1> domen w CI TASK',
            'regform-same-as-buyer': 'Takie jak nabywcy',
            'regform-same-address-as-buyer': 'Taki jak adres nabywcy',
            'regform-same-address-as-payer': 'Taki jak adres płatnika',

            'regform-cancel': 'Przerwij rejestrację',
            'regform-commit': 'Zamawiam z obowiązkiem zapłaty',
            'regform-back': 'Poprzedni krok',
            'regform-forward': 'Następny krok',
            'regform-back-to-main': 'Powrót do strony głównej',

            'regform-error-bad-city-code': 'Nieznane miasto',
            'regform-error-bad-street-code': 'Nieznana ulica',
            'regform-error-bad-street-number': 'Dozowolone litery, cyfry, myślnik, ukośnik, kropka i odstęp',
            'regform-error-consent-required': 'Wymagana zgoda na zasady rejestracji',
            'regform-error-broken-form': 'Zepsuty formularz!',
            'regform-error-domain-taken': 'Domena jest już zarejestrowana',
            'regform-error-send-form-failed': 'Błąd sieci',
            'regform-error-invalid-nip-format': 'Niepoprawny format: powinno być 10 cyfr',
            'regform-error-invalid-nip-checksum': 'Niepoprawny numer NIP',
            'regform-error-unknown-nip': 'Nie znaleziono numeru NIP w bazie REGON',
            'regform-error-there-were-errors': 'Formularz zawiera błędy',
            'regform-error-bad-zip-code': 'Kod pocztowy powinien mieć pięć cyfr, rozdzielonych kreską, np. 12-345',
            'regform-error-invalid-phone': 'Niepoprawny numer',
            'regform-error-invalid-email': 'Niepoprawny email',
            'regform-error-invalid-value': 'Niepoprawna wartość',
            'regform-error-invalid-text': 'Pole nie może kończyć się ani zaczynać odstępami ani być puste',
            'regform-error-invalid-firstname':
                'Imię musi składać się z co najmniej dwóch znaków i nie może zaczynać się ani kończyć odstępami',
            'regform-error-invalid-surname':
                'Nazwisko musi składać się z co najmniej dwóch znaków i nie może zaczynać się ani kończyć odstępami',
            'regform-error-invalid-name':
                'Nazwa musi składać się z co najmniej dwóch znaków i nie może zaczynać się ani kończyć odstępami',
            'regform-error-backend-unsupported_value': 'Nieobsługiwany okres trwania umowy',
            'regform-error-backend-teryt': 'Nieznany kod TERYT',
            'regform-error-backend-correspondence_address_set_incorrectly':
                'Niepoprawnie ustawiony adres do korespondencji',
            'regform-error-backend-str.regex': 'Niepoprawny format wartości',
            'regform-error-backend-any_str.min_length': 'Za krótka wartość',
            'regform-error-backend-any_str.max_length': 'Za długa wartość',
            'regform-error-backend-email': 'Niepoprawny adres',
            // eslint complains in both cases: with line break and without line break
            /* eslint-disable prettier/prettier */
            'regform-error-backend-regon':
                `Dane nie zgadzają się z bazą REGON. Prosimy o kontakt przez e-mail: ${dnsEmail}.`,
            'regform-error-backend-inconsistent_with_database':
                `Dane nie zgadzają się z bazą REGON. Prosimy o kontakt przez e-mail: ${dnsEmail}.`,
            /* eslint-enable prettier/prettier */
            'regform-error-backend-empty_not_allowed': 'Nie może być puste',

            'regform-toggle-city-list': 'Wł./wył. listy podpowiedzi miast',
            'regform-toggle-street-list': 'Wł./wył. listy podpowiedzi ulic',
            'regform-confirmation': 'Potwierdzenie',
            'regform-success': 'Domena została zarejestrowana.',
            'regform-thanks':
                'Dziękujemy za rejestrację domeny. Na podany przez Ciebie adres e-mail zostanie ' +
                'wysłane potwierdzenie transakcji wraz ze szczegółami dotyczącymi płatności.',
            'regform-payment-header': 'Dokonaj płatności',
            'regform-payment-account-info':
                'W celu dokończenia rejestracji prosimy o dokonanie płatności ' +
                'w kwocie <strong>{{amount}}</strong> na wskazany poniżej nr konta:<br/>' +
                'Politechnika Gdańska, CI TASK<br />' +
                'ul. Narutowicza 11/12, 80-233 Gdańsk<br />' +
                `${accountDataBank}<br />` +
                `<strong>${accountDataBankNumber}</strong>` +
                '<br/>Tytuł przelewu: <strong>domena {{domain}}</strong>',

            'province-short': 'woj.',
            'municipality-short': 'gm.',

            'check-domain': 'Sprawdź',
            'is-available': 'Sprawdzanie dostępności domeny',
            'is-available-description':
                'Formularz sprawdza dostępność domen: gda.pl, gdansk.pl, gdynia.pl, sopot.pl i med.pl.',
            'my-domains': 'Moje domeny',
            'registration-rules': 'Regulamin rejestracji domen w CI TASK',

            'navigation section': 'Menu nawigacyjne',
            'footer section': 'Dodatkowe informacje (stopka)',
            'footer-additional': 'Dodatkowe dane adresowe',
            'jump to': 'Przejdź do: {{where}}',

            'toggle-sort-button': 'Sortowanie według: {{column}}',
            'scroll-list-to': 'Przewinięcie do {{domain}}',

            'sorted-ascending': 'uporządkowane rosnąco',
            'sorted-descending': 'uporządkowane malejąco',

            'mobile-menu': 'Menu nawigacji',
            'toggle-account-menu': 'Przełączenie widoczności menu konta',

            faq: 'Najczęściej zadawane pytania',
            'domain-wrapped': 'Dostępne oferty dla\u00a0<strong>{{domain}}</strong>',
        },
    },
}

const languageDetector = new LanguageDetector(null, {
    order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
    lookupQuerystring: 'lng',
    lookupLocalStorage: 'i18nextLng',
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
    htmlTag: document.documentElement,
})

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        ns: ['main'],
        defaultNS: 'main',
        resources,
        supportedLngs: ['en', 'pl'],
        fallbackLng: 'en',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
export { resources }
