import { useTranslation } from 'react-i18next'

const useCurrentLanguage = (): string => {
    const {
        i18n: { language },
    } = useTranslation()
    return language.includes('pl') ? 'pl' : 'en'
}

export { useCurrentLanguage }
