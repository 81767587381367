import React, { ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useSessionInformation } from 'api/access-context'
import { navigateToLogout } from 'routing'
import { MOBILE, CONTENT_FONT, HEADER_TEXT_COLOR, HEADER_BACKGROUND_COLOR } from 'global-style'
import { ImagesPath } from 'paths'

const SessionIcon = styled.img`
    width: 1.75rem;
    height: 1.75rem;
    margin: 0 auto;
    ${MOBILE} {
        display: none;
        width: 0;
    }
`

const SessionLeft = styled.span`
    display: block;
    font-family: ${CONTENT_FONT};
    font-weight: bold;
    font-size: 0.875rem;
    color: ${HEADER_TEXT_COLOR};
    margin: 0.25rem 0 0 0;
    ${MOBILE} {
        font-size: 0.75rem;
        color: white;
        margin: 0;
    }
`

const StyledSessionTimer = styled.button`
    display: inline-block;
    line-height: initial;
    height: auto;
    text-align: center;
    width: 4rem;
    padding: 0.5rem 0 0.5rem 0;
    margin: 0.7rem 0 0 0;
    background-color: ${HEADER_BACKGROUND_COLOR};
    border: none;
    cursor: pointer;
    ${MOBILE} {
        background: none;
        padding: 0;
        margin: 0;
    }
`

const getSecondsToNextTick = (secondsLeft: number): number => {
    if (secondsLeft <= 0) return 0
    if (secondsLeft <= 15) return 1
    if (secondsLeft <= 30) return secondsLeft - 15
    if (secondsLeft <= 45) return secondsLeft - 30
    if (secondsLeft <= 60) return secondsLeft - 45
    return secondsLeft - Math.floor(secondsLeft / 60) * 60
}

const secondsLeftToText = (secondsLeft: number | null, maxPreciseSessionTime: number, t: TFunction): string => {
    if (secondsLeft === null) return ''
    if (secondsLeft <= 15) return t('session-left-seconds', { seconds: Math.max(secondsLeft, 0).toFixed(0) })
    if (secondsLeft < 30) return t('session-left-seconds', { seconds: 15 })
    if (secondsLeft < 45) return t('session-left-seconds', { seconds: 30 })
    if (secondsLeft < 60) return t('session-left-seconds', { seconds: 45 })
    if (secondsLeft > maxPreciseSessionTime) {
        return t('session-left-over-minutes', { minutes: Math.floor(maxPreciseSessionTime / 60) })
    }
    return t('session-left-minutes', { minutes: Math.floor(secondsLeft / 60) })
}

const SessionTimer = ({ className }: { className?: string }): ReactElement | null => {
    const { t } = useTranslation()
    const sessionInfo = useSessionInformation()
    const [secondsLeft, setSecondsLeft] = useState<number | null>(null)
    const navigate = useNavigate()

    if (sessionInfo === undefined || sessionInfo === false) {
        return null
    }

    const { expires, refreshed_ttl, refreshSession } = sessionInfo

    useEffect(() => {
        const secondsTillTimeout = (expires.getTime() - Date.now()) / 1000
        if (secondsTillTimeout <= 0) {
            navigateToLogout(navigate)
            return
        }
        const milisecondsToNextTick = secondsLeft === null ? 0 : getSecondsToNextTick(secondsTillTimeout) * 1000 + 10
        let timer: ReturnType<typeof setTimeout> | null = setTimeout(() => {
            timer = null
            setSecondsLeft((expires.getTime() - Date.now()) / 1000)
        }, milisecondsToNextTick)
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [expires, navigate, secondsLeft])

    return (
        <StyledSessionTimer
            className={className}
            type='button'
            title={t('session-left')}
            onClick={() => {
                refreshSession()
            }}
        >
            <SessionIcon src={`${ImagesPath}/time-icon.svg`} alt='' />
            <SessionLeft>{secondsLeftToText(secondsLeft, refreshed_ttl, t)}</SessionLeft>
        </StyledSessionTimer>
    )
}

export default SessionTimer
