import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
    MOBILE,
    NOT_MOBILE,
    NOT_MOBILE_AFTER_BETWEEN,
    NOT_MOBILE_BETWEEN,
    FOOTER,
    ACCENT_TYPEFACE,
    CONTENT_TYPEFACE,
    MAX_DESKTOP_CONTENT_WIDTH_STYLE,
} from 'global-style'

const StyledFooter = styled.footer`
    font-family: ${ACCENT_TYPEFACE};
    font-weight: normal;
`

const FooterRow = styled.div`
    font-size: 1rem;
`

const StyledSearchRow = styled(FooterRow)`
    background-color: ${FOOTER[0].BACKGROUND};
    color: ${FOOTER[0].TEXT_COLOR};
    padding: 4.75rem 0 4.75rem 0;
    ${MOBILE} {
        padding: 1.25rem 0 2rem 0;
    }
`

const StyledInfoRow = styled(FooterRow)`
    background-color: ${FOOTER[0].BACKGROUND};
    color: ${FOOTER[1].TEXT_COLOR};
    padding: 3rem 0 3rem 0;
    ${MOBILE} {
        padding: 1rem 0 1rem 0;
    }
`

const StyledLink = styled(Link)`
    color: white;
    text-decoration: none;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    font-family: ${CONTENT_TYPEFACE};
`

const StyledCopyrightRow = styled(FooterRow)`
    background-color: ${FOOTER[2].BACKGROUND};
    color: ${FOOTER[2].TEXT_COLOR};
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    padding: 2.625rem 0 2rem 0;
    border-top: white dashed 1px;
    ${MOBILE} {
        padding: 2rem 0 2rem 0;
    }
`

const rowHorizontalLayout = `
    max-width: calc(${MAX_DESKTOP_CONTENT_WIDTH_STYLE} + 3rem);
    margin: auto;
    padding: 0 1.5rem 0 1.5rem;
`
const FooterMargin = styled.div`
    margin-top: 5.688rem;
    margin-bottom: 1rem;
    ${MOBILE} {
        margin: 0;
    }
`
const SearchContactHeader = styled.h2`
    font-family: ${ACCENT_TYPEFACE};
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
    color: inherit;
    margin: 0;
    padding: 0;
    ${MOBILE} {
        text-align: center;
        margin-top: 1.75rem;
    }
`
const Links = styled(FooterMargin)`
    grid-area: links;
    font-style: normal;
    text-align: left;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${NOT_MOBILE} {
        ${SearchContactHeader} {
            padding-left: 0.2rem;
        }
    }
`
const Task = styled(FooterMargin)`
    grid-area: task;
    font-style: normal;
    text-align: left;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${NOT_MOBILE_AFTER_BETWEEN} {
        padding-left: 8.25rem;
        padding-right: 4.813rem;
    }
    ${NOT_MOBILE_BETWEEN} {
        padding-left: 4.813rem;
        padding-right: 4.813rem;
    }
    ${MOBILE} {
        text-align: center;
        margin: 1rem;
    }
`
const SearchContact = styled(FooterMargin)`
    grid-area: contact;
`

const InfoRowContent = styled.div`
    ${rowHorizontalLayout}
    ${Task} {
        grid-area: task;
    }
    ${Links} {
        grid-area: links;
    }
    ${SearchContact} {
        grid-area: contact;
    }
    display: grid;
    grid-template-areas: 'contact task links';
    grid-template-rows: repeat(1, auto);
    grid-template-columns: minmax(auto, 30.875rem) repeat(2, auto);
    ${MOBILE} {
        grid-template-rows: repeat(3, auto);
        grid-template-columns: minmax(1rem, 1fr);
        grid-template-areas:
            'contact'
            'task'
            'links';
    }
`
const SearchRowContent = styled.div`
    ${rowHorizontalLayout}
    display: grid;
    grid-column-gap: 1rem;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: minmax(20rem, 1fr) minmax(min-content, 31rem);
    grid-template-areas:
        'text'
        'search';
    ${MOBILE} {
        grid-template-rows: repeat(3, auto);
        grid-template-columns: minmax(4rem, 1fr);
        grid-template-areas:
            'text'
            'search';
    }
`

const StyledButton = styled.button`
    border: none;
    color: white;
    background: none;
    text-align: left;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: ${CONTENT_TYPEFACE};
    ${MOBILE} {
        text-align: center;
    }
`

const InfoColumnContent = styled.address`
    font-style: normal;
    text-align: left;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${MOBILE} {
        flex-direction: column;
    }
`

const CopyrightRowContent = styled.div`
    ${rowHorizontalLayout}
    text-align: center;
`

const StyledInfoBit = styled.span`
    font-family: ${CONTENT_TYPEFACE};
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    max-width: 100%;
    ${MOBILE} {
        white-space: initial;
    }
`

const InfoBitPrefix = styled.span`
    &::after {
        content: ': ';
    }
`

const StyledInfoBitLink = styled.a`
    text-decoration: none;
    color: inherit;
`

const SearchText = styled.span`
    grid-area: text;
    align-self: start;
    font-size: 2.25rem;
    color: inherit;
    margin: -0.25rem 0 0 0;
    padding: 0;
    & strong {
        font-family: ${ACCENT_TYPEFACE};
        font-weight: bold;
    }
    ${MOBILE} {
        display: none;
    }
`
const SearchContactText = styled.div`
    margin: 1.875rem 0 0 0;
    font-family: ${CONTENT_TYPEFACE};
    font-size: 0.875rem;
    ${MOBILE} {
        text-align: center;
    }
`

const SearchContactPointers = styled.div`
    margin: 1.25rem 0 0 0;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 1rem;
    ${MOBILE} {
        justify-content: space-around;
        row-gap: 0.25rem;
    }
`

export {
    StyledFooter,
    FooterRow,
    StyledSearchRow,
    StyledInfoRow,
    StyledCopyrightRow,
    SearchRowContent,
    InfoColumnContent,
    CopyrightRowContent,
    StyledInfoBit,
    InfoBitPrefix,
    InfoRowContent,
    StyledInfoBitLink,
    SearchText,
    SearchContact,
    SearchContactHeader,
    SearchContactText,
    SearchContactPointers,
    Links,
    Task,
    StyledButton,
    StyledLink,
}
