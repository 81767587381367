import { useQuery, UseQueryResult } from 'react-query'

import { apiFetch } from 'api/fetch'
import { UnexpectedError } from 'api/errors'

export type RefetchFunction = () => Promise<UseQueryResult>

const useAPIQuery = <T>(path: string, options: Record<string, unknown> = {}): T => {
    const { data, isError, error } = useQuery([path], async (): Promise<T> => apiFetch<T>(path, options), {
        suspense: true,
        useErrorBoundary: true,
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })
    if (isError) {
        /* error should have been thrown as an exception, so isError cannot be true here */
        throw new UnexpectedError(`useAPIQuery(${path})`, `error was not thrown: ${error}`)
    }
    if (data === undefined) {
        /* data cannot be undefined since wait should have been passed to the containing Suspense */
        throw new UnexpectedError(`useAPIQuery(${path})`, 'wait was not propagated')
    }
    return data
}

export const useConditionalAPIQuery = <T>(
    path: string,
    options: Record<string, unknown> = {},
    enabled: boolean,
): T | null => {
    const { data, isError, error } = useQuery([path], async (): Promise<T> => apiFetch<T>(path, options), {
        suspense: true,
        useErrorBoundary: true,
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: enabled,
    })
    if (isError) {
        /* error should have been thrown as an exception, so isError cannot be true here */
        throw new UnexpectedError(`useAPIQuery(${path})`, `error was not thrown: ${error}`)
    }
    if (data === undefined) {
        if (enabled === false) {
            return null
        }
        /* data cannot be undefined since wait should have been passed to the containing Suspense */
        throw new UnexpectedError(`useAPIQuery(${path})`, 'wait was not propagated')
    }
    return data
}

export const useAPIQueryWithRefetch = <T>(
    path: string,
    options: Record<string, unknown> = {},
): [T, RefetchFunction] => {
    const { data, isError, error, refetch } = useQuery([path], async (): Promise<T> => apiFetch<T>(path, options), {
        suspense: true,
        useErrorBoundary: true,
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })
    if (isError) {
        /* error should have been thrown as an exception, so isError cannot be true here */
        throw new UnexpectedError(`useAPIQuery(${path})`, `error was not thrown: ${error}`)
    }
    if (data === undefined) {
        /* data cannot be undefined since wait should have been passed to the containing Suspense */
        throw new UnexpectedError(`useAPIQuery(${path})`, 'wait was not propagated')
    }
    return [data, refetch]
}

export default useAPIQuery
