import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { HEADER_BACKGROUND_COLOR, MOBILE, NOT_MOBILE, BUTTON_BACKGROUND_MOBILE } from 'global-style'

const StyledHeader = styled.header`
    display: flex;
    margin: 0;
    box-sizing: border-box;
    flex-grow: 0;
    flex-direction: row;
    background-image: none;
    background-color: ${HEADER_BACKGROUND_COLOR};
    font-size: 14px;
    min-height: 150px;
    max-height: 30rem;
    justify-content: center;
    ${MOBILE} {
        padding: 0;
        min-height: 1rem;
        max-height: 6rem;
    }
`
const Div = styled.div`
    max-width: 1320px;
    width: 1320px;
    margin: 1rem;
    ${MOBILE} {
        width: 100%;
    }
`

export const StyledLogo = styled.img`
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    width: 6.25rem;
    height: auto;
    ${MOBILE} {
        width: 4rem;
        padding-right: 0.2rem;
        margin: 0;
    }
`
export const MobileImg = styled.img`
    width: 2.2rem;
    margin-top: 1.3rem;
    ${NOT_MOBILE} {
        width: 0;
        display: none;
    }
`
export const NavWrapper = styled.div`
    width: 100%;
`
export const Crossmark = styled.button`
    cursor: pointer;
    width: 3rem;
    height: 2.5rem;
    padding: 0;
    font-size: 2rem;
    margin: 1.3rem;
    color: white;
    border: none;
    background-color: ${BUTTON_BACKGROUND_MOBILE};
    border-radius: 1rem;
    ${NOT_MOBILE} {
        display: none;
        visibility: hidden;
        width: 0;
    }
`

type PHeader = {
    className?: string
    children?: ReactNode
    title: string
}

const Header = ({ className, children }: PHeader): ReactElement => {
    return (
        <StyledHeader className={className}>
            <Div>{children}</Div>
        </StyledHeader>
    )
}

export default Header
