import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { getMessageForError } from 'api/errors'
import { ErrorInformationStyled, ErrorInformationTitle, ErrorInformationMessage } from './error-information-styled'

function ErrorInformation({ error }: { error: Error }): ReactElement {
    const { t } = useTranslation()
    return (
        <ErrorInformationStyled>
            <ErrorInformationTitle>{t('error-occured')}</ErrorInformationTitle>
            <ErrorInformationMessage>{t('error-contact')}</ErrorInformationMessage>
            <ErrorInformationMessage>{getMessageForError(error, t)}</ErrorInformationMessage>
        </ErrorInformationStyled>
    )
}

export default ErrorInformation
