import { createGlobalStyle } from 'styled-components'

import { FontsPath } from 'paths'

export const MAX_DESKTOP_CONTENT_WIDTH = 1320
export const MAX_DESKTOP_CONTENT_WIDTH_STYLE = `${MAX_DESKTOP_CONTENT_WIDTH}px`

export const MOBILE = `@media (max-width: 480px)`
export const NOT_MOBILE = `@media (min-width: 481px)`
export const NOT_MOBILE_BETWEEN = `@media (min-width: 481px) and (max-width: 950px)`
export const NOT_MOBILE_AFTER_BETWEEN = `@media (min-width: 951px)`
export const NARROW_DESKTOP = NOT_MOBILE_BETWEEN
export const WIDE_DESKTOP = NOT_MOBILE_AFTER_BETWEEN
export const MOBILE_OR_NARROW_DESKTOP = `@media (max-width: 950px)`

export const DEFAULT_BLUE = '#003767'
export const DEFAULT_RED = '#DF004C'
export const DEFAULT_GREEN = '#5A8D21'
export const INFO_BLUE = '#1774C7'
// export const ERROR_RED = '#df494a'   // fails WCAG contrast test
export const ERROR_RED = '#ad1f1f'
export const SHADOW_COLOR = '#D7E2EC'
// export const SEPARATOR_COLOR = '#BDD0E23F'  // with explicit alpha
export const SEPARATOR_COLOR = '#E9F3F7' // pre-applied alpha
export const HIGHLIGHTED_BACKGROUND = '#EEF3F8'

export const NAVIGATION_ACTIVE_ICON_SHADOW_COLOR = '#00406B'

export const BUTTON_BACKGROUND_COLOR = `rgb(56, 93, 156)`
export const BUTTON_TEXT_COLOR = `rgb(255, 255, 255)`
export const BUTTON_SEND_DATA_BACKGROUND_COLOR = `rgb(203, 44, 39)`
export const BUTTON_REGISTER_COLOR = '#043865'
export const BUTTON_BACKGROUND_MOBILE = '#e73d77'

export const HEADER_TEXT_COLOR = DEFAULT_BLUE
export const HEADER_BACKGROUND_COLOR = '#FFFFFF'

export const HEADER_BUTTON_COLOR = DEFAULT_BLUE
export const HEADER_BUTTON_LOGIN_COLOR = DEFAULT_RED
export const BUTTON_WHITE_TEXT_COLOR = '#f5fafe'
export const HEADER_ACCOUNT_MOBILE_INFO = '#c70044'
export const SEARCH_BOX_BACKGROUND_COLOR = `#E8F0F7`

export const FOOTER = [
    {
        BACKGROUND: '#0C4A80',
        TEXT_COLOR: '#ffffff',
    },
    {
        BACKGROUND: DEFAULT_BLUE,
        TEXT_COLOR: '#ffffff',
    },
    {
        BACKGROUND: '#0C4A80',
        TEXT_COLOR: '#ffffff',
    },
]

const SANUK_TYPEFACE = '"SanukPro",Helvetica,Arial,Lucida,sans-serif'
const OPEN_SANS_TYPEFACE = '"OpenSans",Helvetica,Arial,Lucida,sans-serif'
export const ACCENT_TYPEFACE = SANUK_TYPEFACE
export const HEADER_LOGO_TYPEFACE = OPEN_SANS_TYPEFACE
export const CONTENT_TYPEFACE = OPEN_SANS_TYPEFACE

export const HEADER_LOGO_COLOR = '#115692'
export const HEADER_LOGO_BORDER_COLOR = DEFAULT_BLUE

export const SKIP_LINK_BACKGROUND = '#ffffff'
export const SKIP_LINK_TEXT = DEFAULT_BLUE
export const SKIP_LINK_FONT_SIZE = '0.875rem'
export const DOMAIN_SEPARATOR_COLOR = '#d7e2ec'

export const DOMAIN_SEARCH_TEXT = '#000000'
export const DOMAIN_SEARCH_BACKGROUND = '#ffffff'
export const DOMAIN_SEARCH_PLACEHOLDER = '#6f6f6f'
export const DOMAIN_SEARCH_BUTTON_TEXT = '#ffffff'
export const DOMAIN_SEARCH_BUTTON_BACKGROUND = DEFAULT_RED
export const DOMAIN_SEARCH_AVAILABLE_SIGN = `#61bc2c`
export const DOMAIN_UPDATED_RECORD_TEXT = `#555555`
export const DOMAIN_UPDATED_RECORD_SHADOW = `${DOMAIN_UPDATED_RECORD_TEXT}80`

export const TABLE_BORDER_COLOR = '#ddd'
export const TABLE_BORDER = `1px solid ${DOMAIN_SEPARATOR_COLOR}`

export const CONTENT_BACKGROUND_COLOR = '#ffffff'
export const CONTENT_ALTERNATE_BACKGROUND_COLOR = '#e8f0f7'
export const CONTENT_TEXT_COLOR = DEFAULT_BLUE
export const CONTENT_ADVERTISEMENT_TEXT_COLOR = '#115692'
export const CONTENT_ADVERTISEMENT_BACKGROUND_COLOR = CONTENT_ALTERNATE_BACKGROUND_COLOR
export const CONTENT_ERROR_TEXT_COLOR = ERROR_RED
export const CONTENT_ERROR_BACKGROUND_COLOR = `${ERROR_RED}26`
export const CONTENT_ERROR_BORDER_COLOR = ERROR_RED
export const CONTENT_TEXT_SIZE = '1rem'
export const CONTENT_FONT = SANUK_TYPEFACE
export const REGISTRATION_FORM_FONT = OPEN_SANS_TYPEFACE
export const REGISTRATION_FORM_HEADER_FONT = SANUK_TYPEFACE
export const REGISTRATION_FORM_BUTTONS_FONT = SANUK_TYPEFACE

export const INACTIVE_SORT_COLOR = `${CONTENT_TEXT_COLOR}80`

export const EXPIRED_COLOR = '#d51111'
export const NEARLY_EXPIRED_COLOR = EXPIRED_COLOR
export const MOBILE_DOMAIN_SHADOW_COLOR = '#e8f0f7'

export const NEW_DOMAIN_COLOR = EXPIRED_COLOR
export const BLOCKED_DOMAIN_COLOR = EXPIRED_COLOR
export const EXPIRED_DOMAIN_COLOR = EXPIRED_COLOR
export const UPDATED_DOMAIN_COLOR = EXPIRED_COLOR

export const HEADER_ACCOUNT_FONT = 'Sanuk,Helvetica,Arial,Lucida,sans-serif'
export const HEADER_FONT_LIGHT = "'SanukPro-Light', 'SanukPro-Medium',Helvetica,Arial,Lucida,sans-serif"
export const HEADER_FONT_MEDIUM = "'SanukPro-Medium',Helvetica,Arial,Lucida,sans-serif"

export const DOMAIN_DETAILS_SHADOW = `0px 0px 0.375rem ${SHADOW_COLOR}`
export const DOMAIN_DETAILS_SEPARATOR = `2px solid ${SEPARATOR_COLOR}`
export const DOMAIN_DETAILS_PERSON_BACKGROUND = HIGHLIGHTED_BACKGROUND
export const DOMAIN_DETAILS_MOBILE_MENU_BACKGROUND = '#F7F9FB'
export const DOMAIN_DETAILS_REMOVE_RECORD_BUTTON_COLOR = DEFAULT_RED

export const WARNING_SYMBOL_TEXT_COLOR = 'white'
export const WARNING_SYMBOL_FONT = OPEN_SANS_TYPEFACE
export const CHECKMARK_SYMBOL_FONT = 'serif'

export const FOCUS_OUTLINE_WIDTH = '0.125rem'
export const FOCUS_OUTLINE_TYPE = 'dashed'
export const FOCUS_OUTLINE_COLOR = '#e02227'
//export const FOCUS_OUTLINE_SECOND_COLOR = '#55cc11'
export const FOCUS_OUTLINE_SECOND_COLOR = 'white'
export const FOCUS_OUTLINE = `${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_TYPE} ${FOCUS_OUTLINE_COLOR}`
export const FOCUS_OUTLINE_SHADOW = `
    ${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_WIDTH} 0px ${FOCUS_OUTLINE_SECOND_COLOR},
    ${FOCUS_OUTLINE_WIDTH} -${FOCUS_OUTLINE_WIDTH} 0px ${FOCUS_OUTLINE_SECOND_COLOR},
    -${FOCUS_OUTLINE_WIDTH} ${FOCUS_OUTLINE_WIDTH} 0px ${FOCUS_OUTLINE_SECOND_COLOR},
    -${FOCUS_OUTLINE_WIDTH} -${FOCUS_OUTLINE_WIDTH} 0px ${FOCUS_OUTLINE_SECOND_COLOR}
`

//export const INPUT_BORDER_COLOR = `${CONTENT_TEXT_COLOR}59`
export const INPUT_BORDER_COLOR = `#a6b9ca` // precomputed for white background
export const INPUT_ALTERNATE_BORDER_COLOR = `#C2D0DB`
export const RADIO_FILL_COLOR = CONTENT_TEXT_COLOR
//export const REGISTRATION_FORM_BORDER_COLOR = `${CONTENT_TEXT_COLOR}19`
export const REGISTRATION_FORM_SHADOW = `0px 0px 0.375rem ${SHADOW_COLOR}`
export const REGISTRATION_FORM_SEPARATOR = `2px solid ${SEPARATOR_COLOR}`
export const REGISTRATION_FORM_ENTITY_BACKGROUND = HIGHLIGHTED_BACKGROUND

//export const REGISTRATION_COMPLETE_HEADER_BACKGROUND = `${DEFAULT_GREEN}26`
export const REGISTRATION_COMPLETE_HEADER_BACKGROUND = '#E6EEDE' // precomputed for white background
export const REGISTRATION_COMPLETE_HEADER_BORDER_COLOR = DEFAULT_GREEN
export const REGISTRATION_COMPLETE_HEADER_ICON_BACKGROUND = DEFAULT_GREEN
export const REGISTRATION_COMPLETE_HEADER_ICON_COLOR = '#ffffff'
//export const REGISTRATION_COMPLETE_HEADER_TEXT_COLOR = DEFAULT_GREEN // fails WCAG contrast test
export const REGISTRATION_COMPLETE_HEADER_TEXT_COLOR = '#355313'
//
//export const REGISTRATION_COMPLETE_HEADER_BACKGROUND = `${INFO_BLUE}26`
export const REGISTRATION_COMPLETE_INFO_BACKGROUND = '#DCEAF7' // precomputed for white background
export const REGISTRATION_COMPLETE_INFO_BORDER_COLOR = INFO_BLUE
export const REGISTRATION_COMPLETE_INFO_ICON_BACKGROUND = '#1197D5'
export const REGISTRATION_COMPLETE_INFO_ICON_COLOR = '#ffffff'
//export const REGISTRATION_COMPLETE_INFO_TEXT_COLOR = INFO_BLUE // fails WCAG contrast test
export const REGISTRATION_COMPLETE_INFO_TEXT_COLOR = '#104E84'
export const REGISTRATION_COMPLETE_CONTENT_FONT = OPEN_SANS_TYPEFACE

export const REGISTRATION_HIGHLIGHTED_DOMAIN_COLOR = DEFAULT_BLUE

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "SanukPro";
        font-weight: 100 400;
        src:local("Sanuk Pro Light"), local("SanukPro-Light"),
        url("${FontsPath}/SanukPro-Light.ttf") format("truetype");
    }
    @font-face {
        font-family: "SanukPro";
        font-weight: 500 900;
        src:local("Sanuk Pro Medium"), local("SanukPro-Medium"),
        url("${FontsPath}/SanukPro-Medium.ttf") format("truetype");
    }
    @font-face {
        font-family: "OpenSans";
        font-weight: 300 500;
        src:local("Open Sans Regular"), local("OpenSans-Regular"),
        url("${FontsPath}/OpenSans-Regular.ttf") format("truetype");
    }
    @font-face {
        font-family: "OpenSans";
        font-weight: 600;
        src:local("Open Sans Semibold"), local("OpenSans-Semibold"),
        url("${FontsPath}/OpenSans-Semibold.ttf") format("truetype");
    }
    @font-face {
        font-family: "OpenSans";
        font-weight: 700;
        src:local("Open Sans Bold"), local("OpenSans-Bold"),
        url("${FontsPath}/OpenSans-Bold.ttf") format("truetype");
    }
    html {
        margin: 0;
        padding: 0;
    }
    body {
        margin: 0;
        padding: 0;
        min-height: 100vh;
        min-width: 17rem;
        background-color: rgb(245, 250, 255);
    }
    #root {
        margin: 0;
        padding: 0;
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
    h1, h2, h3, h4, h5, h6 {
        padding-bottom: 10px;
        line-height: 1em;
        font-weight: 500;
        color: ${CONTENT_TEXT_COLOR};
        font-family: ${SANUK_TYPEFACE};
    }
    *, *:before, *:after {
        box-sizing: border-box;
    }
    *:focus {
        outline: ${FOCUS_OUTLINE};
        box-shadow: ${FOCUS_OUTLINE_SHADOW};
    }

    button {
        cursor: pointer;
    }

    input[type=submit] {
        cursor: pointer;
    }
`

export default GlobalStyle
