import { TFunction } from 'i18next'
import { PriceDomain } from 'api/price-domain'

export const textFromPrice = (price: PriceDomain, t: TFunction): string => {
    if (typeof price.price_brutto === 'string') {
        return t('price-individual')
    }
    return `${price.price_brutto} ${price.unit}`
}
export const textFromRoundedPrice = (price: PriceDomain, t: TFunction): string => {
    if (typeof price.price_brutto === 'string') {
        return t('price-individual')
    }
    return t('top-three-price', { price: `${Math.round(price.price_brutto / 10).toFixed(0)} ${price.unit}` })
}
