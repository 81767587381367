function getQueryParam(param: string, href?: string): string | null {
    const url = href !== undefined ? href : window.location.href
    const name = param.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
    const results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return null
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export default getQueryParam
