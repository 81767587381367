import { useContext, createContext } from 'react'
import { SessionInformation as SessionInfo } from 'hooks/access-check'

export type SessionInformation = SessionInfo

const AccessContext = createContext<SessionInformation | false>(false)

const SessionInformationContext = AccessContext.Provider

const useSessionInformation = (): SessionInformation | false => useContext(AccessContext)

export default SessionInformationContext
export { useSessionInformation }
