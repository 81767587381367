import styled from 'styled-components'
import { CONTENT_BACKGROUND_COLOR, CONTENT_TEXT_COLOR, MOBILE } from 'global-style'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { DomainInformationWithNameAndState } from 'pages/domains/domains-tab'

export const SelectorGroup = styled.div`
    display: none;
    ${MOBILE} {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin: 0 0 2.25rem;
    }
`

export const SelectorStyled = styled.button`
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.4375rem;
    border: 0.25px solid ${CONTENT_TEXT_COLOR};
    background-color: ${CONTENT_BACKGROUND_COLOR};
    &.current {
        background-color: ${CONTENT_TEXT_COLOR};
    }
`

const Selector = ({
    data,
    scrollTo,
    scrolledTo,
}: {
    data: DomainInformationWithNameAndState[] | string[]
    scrollTo: (index: number) => void
    scrolledTo: number
}): ReactElement => {
    const { t } = useTranslation()
    return (
        <SelectorGroup>
            {data.map((item, index) => (
                <SelectorStyled
                    type='button'
                    key={typeof item === 'string' ? item : item.name}
                    title={t('scroll-list-to', { domain: typeof item === 'string' ? item : item.name })}
                    onClick={() => scrollTo(index)}
                    className={scrolledTo === index ? 'current' : undefined}
                />
            ))}
        </SelectorGroup>
    )
}

export default Selector
