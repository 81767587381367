import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
    NAVIGATION_ACTIVE_ICON_SHADOW_COLOR,
    BUTTON_BACKGROUND_COLOR,
    TABLE_BORDER_COLOR,
    BUTTON_REGISTER_COLOR,
    HEADER_BUTTON_LOGIN_COLOR,
    BUTTON_WHITE_TEXT_COLOR,
    DOMAIN_SEARCH_BUTTON_TEXT,
    DOMAIN_SEARCH_BUTTON_BACKGROUND,
    ACCENT_TYPEFACE,
    CONTENT_TYPEFACE,
    MOBILE,
    DOMAIN_SEARCH_TEXT,
    DOMAIN_SEARCH_BACKGROUND,
    DOMAIN_SEARCH_PLACEHOLDER,
    NOT_MOBILE,
    FOOTER,
    CONTENT_BACKGROUND_COLOR,
    BUTTON_TEXT_COLOR,
    TABLE_BORDER,
    CONTENT_TEXT_SIZE,
    CONTENT_TEXT_COLOR,
    MOBILE_DOMAIN_SHADOW_COLOR,
    DOMAIN_SEPARATOR_COLOR,
} from 'global-style'
import { PageHeader } from '../section'

const dashedSVG = `
<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
<line x1='0' y1='100%' x2='100%' y2='100%' fill='none' stroke='${FOOTER[0].TEXT_COLOR}'
 stroke-width='2' stroke-dasharray='3,3' stroke-dashoffset='0' stroke-linecap='butt'/>
</svg>`

const searchBoxBackgroundUrl = `url("data:image/svg+xml,${encodeURIComponent(dashedSVG)}")`

export const CheckDomainButton = styled.button`
    background-color: ${HEADER_BUTTON_LOGIN_COLOR};
    display: block;
    color: ${BUTTON_WHITE_TEXT_COLOR};
    border: none;
    border-radius: 1.25rem;
    text-align: center;
    text-decoration: none;
    padding: 1rem 1rem 0.8rem 1rem;
    margin: 0 1rem 1rem 1rem;
    width: 10rem;
    line-height: 1rem;
    height: 3.688rem;
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    font-size: 1.375rem;

    &:hover {
        background-color: ${NAVIGATION_ACTIVE_ICON_SHADOW_COLOR};
    }
    &:active {
        background-color: ${BUTTON_BACKGROUND_COLOR};
    }
`
export const StyledText = styled.div`
    font: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    padding: 0.5em 0 0.5em 0;
    display: block;
`
export const StyledHeaderText = styled(StyledText)`
    font-weight: bold;
    padding: 0.5em 0 0.5em 0;
`

export const SearchIcon = styled.img`
    width: 1.375rem;
    height: 1.375rem;
    margin: 0 1.5rem 0 0;
    padding: 0;
    ${MOBILE} {
        position: absolute;
        left: 1.375rem;
        top: 2rem;
        transform: translate(0, -50%);
    }
`

export const SearchButton = styled.button`
    flex: 0 0 auto;
    border-radius: 1.375rem;
    height: 3.75rem;
    padding: 0 2.5rem 0 2.5rem;
    margin: 0 0 0 0.5rem;
    color: ${DOMAIN_SEARCH_BUTTON_TEXT};
    background-color: ${DOMAIN_SEARCH_BUTTON_BACKGROUND};
    border: none;
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    font-size: inherit;
    cursor: pointer;
    ${MOBILE} {
        height: 3.255rem;
        margin: 1.25rem 0 0 3.5rem;
        padding: 0.5rem 0 0 0;
        width: 11rem;
        font-size: 1rem;
        text-align: center;
    }
`

export const StyledSearchForm = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 5.25rem;
    width: 100%;
    margin: 1.5rem 0 0 0;
    padding: 0 1.375rem 0 1.375rem;
    border-radius: 1.375rem;
    background-color: ${DOMAIN_SEARCH_BACKGROUND};
    grid-area: search;
    font-size: 1.375rem;
    position: relative;
    ${MOBILE} {
        margin: 1.25rem 0 0 0;
        padding: 0 0 1.75rem 0;
        min-height: 10rem;
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: minmax(1rem, 1fr);
        grid-template-areas:
            'icon'
            'button';
        justify-content: center;
        background-color: inherit;
        border-radius: 0;
        background-image: ${searchBoxBackgroundUrl};
        ${SearchIcon} {
            grid-area: icon;
        }
        ${SearchButton} {
            grid-area: button;
            max-width: 15rem;
        }
    }
`
export const Input = styled.div``

export const Wrapper = styled.div`
    display: grid;
    ${NOT_MOBILE} {
        margin: 3rem 2rem 2rem 2rem;
    }
`
export const Output = styled.div``

export const WrapperForInput = styled.div``
export const AbsoluteForInput = styled.div``
export const StyledInput = styled.input`
    width: 100%;
    max-width: 37em;
    padding: 0.5em;
    margin: 0.5em 0 0.5em 0;
`

export const Form = styled.form``

export const SearchValueInput = styled.input`
    flex: 1 1 auto;
    border: none;
    width: 100%;
    min-width: 4rem;
    color: ${DOMAIN_SEARCH_TEXT};
    background-color: ${DOMAIN_SEARCH_BACKGROUND};
    font-family: ${ACCENT_TYPEFACE};
    font-weight: normal;
    font-size: inherit;
    &::placeholder {
        color: ${DOMAIN_SEARCH_PLACEHOLDER};
    }
    &:focus {
        border: none;
        outline: none;
    }
    &:invalid {
        color: red;
    }
    ${MOBILE} {
        border-radius: 1.375rem;
        padding: 0 2.5rem 0 3rem;
        min-height: 3.813rem;
        max-width: 18rem;
        border: none;
        &:focus {
            border: 2px solid red;
            outline: none;
        }
    }
`

export const AvailableDomainsLink = styled(Link)`
    border: 3px solid ${BUTTON_REGISTER_COLOR};
    text-decoration: none;
    background-color: ${DOMAIN_SEARCH_BACKGROUND};
    color: ${BUTTON_REGISTER_COLOR};
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    font-size: 0.875rem;
    border-radius: 1rem;
    text-align: center;
    padding: 1.1rem 1rem 0.9rem 1rem;
    cursor: pointer;
    width: 10.188rem;
    height: 3.188rem;
    ${MOBILE} {
        font-family: ${ACCENT_TYPEFACE};
        font-size: 1rem;
        background-color: ${BUTTON_REGISTER_COLOR};
        color: ${BUTTON_TEXT_COLOR};
        margin-top: 1rem;
        border: none;
        height: 1.25rem;
        width: 10rem;
    }
`

export const AvailableDomainsTable = styled.table`
    width: calc(100% - 2.25rem);
    margin: 4rem 0.5rem 5.625rem 1.75rem;
    line-height: 1.43;
    vertical-align: top;
    border-collapse: collapse;
    border-right: none;
    border-left: none;
    font-size: ${CONTENT_TEXT_SIZE};

    & thead {
        border-bottom: ${TABLE_BORDER};
        font-family: ${ACCENT_TYPEFACE};
        font-weight: bold;
    }

    & tbody {
        font-family: ${CONTENT_TYPEFACE};
        font-size: 1rem;
        font-weight: normal;
    }

    & tr {
        border-bottom: ${TABLE_BORDER};
    }

    & th {
        padding: 2rem 0 2rem 0;
    }

    & td {
        padding: 2rem 0.5rem 2rem 0;
    }

    & td:last-child {
        text-align: right;
    }
    & td:first-child {
        text-align: left;
        padding-right: 0;
    }
    & th:first-child {
        text-align: left;
        padding-right: 0;
    }
    ${MOBILE} {
        display: block;
        width: 100%;
        overflow-x: hidden;
        margin: 2.25rem 0 1.375rem 0;

        & tbody {
            overflow: scroll;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            scroll-snap-type: x mandatory;
        }

        & tbody::-webkit-scrollbar {
            display: none;
        }

        & thead {
            display: none;
        }

        & th {
            border-bottom: 1px solid ${DOMAIN_SEPARATOR_COLOR};
        }

        & tr {
            padding-top: 1rem;
            flex: 0 0 auto;
            display: block;
            width: calc(100vw - 4rem);
            height: fit-content;
            border-radius: 1.375rem;
            margin: 0.2rem 1rem 0.2rem 1rem;
            box-shadow: 0px 0px 0.2rem 0.15rem ${MOBILE_DOMAIN_SHADOW_COLOR};
            position: relative;
            scroll-snap-align: center;
            scroll-snap-stop: always;
        }

        & td {
            display: block;
            position: relative;
            font-size: 1rem;
            overflow: hidden;
            word-break: break-all;
            border-bottom: 1px solid ${DOMAIN_SEPARATOR_COLOR};
            text-align: left;
            padding: 2.5rem 0.5rem 1rem 1rem;
            margin-right: 1rem;
        }

        & td:not(:last-child):before {
            text-align: left;
            display: block;
            content: attr(data-column-name) ':';
            position: absolute;
            left: 0;
            top: 0.4rem;
            width: min(60vw - 4.2rem - 0.25rem, 8.75rem);
            font-family: ${CONTENT_TYPEFACE};
            font-weight: bold;
            color: ${CONTENT_TEXT_COLOR};
            overflow: hidden;
            padding-left: 1rem;
            padding-top: 0.5rem;
        }
        & td:last-child {
            text-align: center;
            margin-bottom: 1rem;
            border-bottom: none;
            margin-right: 0;
        }
    }
`
export const SearchValueFocusBorder = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    pointer-events: none;
    ${NOT_MOBILE} {
        input[type='text']:focus + & {
            border: 2px solid red;
            border-radius: 1.375rem;
        }
    }
`

export const TextWrapper = styled.div`
    font-family: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    font-size: 2.125rem;
    justify-content: center;
    margin-bottom: 1rem;
    overflow-wrap: break-word;
    width: 20rem;
    ${MOBILE} {
        display: inline-block;
        margin: 0;
        font-size: 1.5rem;
    }
`
export const TextWrapperRow = styled.div`
    font-family: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    font-size: 2.125rem;
    justify-content: center;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    ${MOBILE} {
        flex-direction: column;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
`
export const TextWrapperBold = styled.strong`
    font-weight: bold;
    font-family: ${ACCENT_TYPEFACE};
    font-size: 2.125rem;
`

export const BestDomains = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100rem;
    ${MOBILE} {
        display: none;
    }
`

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const HeightForTextWrapper = styled.div`
    height: fit-content;
    margin: 0.5rem;
`

export const AvailableDomainBox = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border: 1px solid ${TABLE_BORDER_COLOR};
    background-color: ${CONTENT_BACKGROUND_COLOR};
    border-radius: 2rem;
    width: 25rem;
    height: fit-content;
    padding-top: 4rem;
    margin: 0.5rem;
    font-family: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    font-size: 1.5rem;
`
export const RegisterDomainLink = styled(Link)`
    text-decoration: none;
    background-color: ${BUTTON_REGISTER_COLOR};
    color: ${BUTTON_TEXT_COLOR};
    font-family: ${ACCENT_TYPEFACE};
    font-weight: bold;
    font-size: 1.25rem;
    border-radius: 1rem;
    text-align: center;
    padding-top: 1.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: none;
    cursor: pointer;
    height: 3.563rem;
    width: 10.188rem;
`

export const TopThreePrice = styled.div`
    font-family: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    font-size: 2.938rem;
    color: #003767;
    margin-top: 2rem;
`

export const ErrorMessage = styled.h1`
    color: red;
    font-size: 1rem;
    margin-left: 0.5rem;
`
export const H1 = styled(PageHeader)`
    font-family: ${ACCENT_TYPEFACE};
    font-weight: lighter;
    font-size: 2.125rem;
    justify-content: center;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    ${MOBILE} {
        flex-direction: column;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    & strong {
        font-weight: bold;
    }
`
