import React, { ReactElement } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'

import { FooterSection } from 'components/section'

import {
    StyledFooter,
    StyledSearchRow,
    StyledInfoRow,
    SearchRowContent,
    StyledInfoBit,
    InfoBitPrefix,
    InfoRowContent,
    StyledInfoBitLink,
    SearchText,
    SearchContact,
    SearchContactHeader,
    SearchContactText,
    SearchContactPointers,
    StyledButton,
    Links,
    Task,
} from './footer-styled'
import { CONTENT_TYPEFACE } from 'global-style'
import { useCurrentLanguage } from 'hooks'

type WithClassName = {
    className?: string
}

type WithPrefix = {
    prefix: boolean
}

type PInfoBit = WithPrefix & WithClassName

const targetForProtocol = (protocol: string, target: string): string => {
    if (protocol === 'tel') {
        return `${protocol}:${target.replace(/ /g, '')}`
    } else if (protocol === 'raw') {
        return target
    } else {
        return `${protocol}:${target}`
    }
}

const InfoBitLink = ({ protocol, target }: { protocol: string; target: string }): ReactElement => (
    <StyledInfoBitLink href={targetForProtocol(protocol, target)}>{target}</StyledInfoBitLink>
)

const InfoBit = ({
    text,
    protocol,
    prefix,
    className,
}: { text: string; protocol?: string } & PInfoBit): ReactElement => {
    const { t } = useTranslation()
    return (
        <StyledInfoBit className={className}>
            {prefix && <InfoBitPrefix>{t(text)}</InfoBitPrefix>}
            {protocol ? <InfoBitLink protocol={protocol} target={t(`${text}-value`)} /> : t(`${text}-value`)}
        </StyledInfoBit>
    )
}

const UrlInfoBit = styled(InfoBit)`
    font-family: ${CONTENT_TYPEFACE};
    font-weight: bold;
`

const AddressPostal = ({ ...params }: PInfoBit): ReactElement => <InfoBit text={'addresspostal'} {...params} />
const Address = ({ ...params }: PInfoBit): ReactElement => <InfoBit text={'address'} {...params} />
const Phone = ({ ...params }: PInfoBit): ReactElement => <InfoBit text={'phone'} protocol='tel' {...params} />
const Email = ({ ...params }: PInfoBit): ReactElement => <InfoBit text={'email'} protocol='mailto' {...params} />
const NIP = ({ ...params }: PInfoBit): ReactElement => <InfoBit text={'nip'} {...params} />
const REGON = ({ ...params }: PInfoBit): ReactElement => <InfoBit text={'regon'} {...params} />
const Url = ({ ...params }: PInfoBit): ReactElement => <UrlInfoBit text={'url'} protocol='raw' {...params} />

// eslint-disable-next-line @typescript-eslint/no-unused-vars

const ContactInfo = (): ReactElement => {
    const { t } = useTranslation()
    return (
        <SearchContact>
            <SearchContactHeader>{t('contact')}</SearchContactHeader>
            <SearchContactText>{t('contact-info')}</SearchContactText>
            <SearchContactPointers>
                <Phone prefix={false} />
                <Email prefix={false} />
                <Url prefix={false} />
            </SearchContactPointers>
        </SearchContact>
    )
}

export const SearchRow = ({ className }: WithClassName): ReactElement => {
    return (
        <StyledSearchRow className={className}>
            <SearchRowContent>
                <SearchText>
                    <Trans i18nKey='footer-ad'>
                        Nie czekaj, zarejestruj <strong>domenę</strong> już teraz!
                    </Trans>
                </SearchText>
                {/* <SearchBox /> */}
            </SearchRowContent>
        </StyledSearchRow>
    )
}

const TaskInfo = (): ReactElement => {
    const { t } = useTranslation()
    return (
        <Task>
            <SearchContactHeader>{t('footer-additional')}</SearchContactHeader>
            <SearchContactText />
            <Address prefix={false} />
            <AddressPostal prefix={false} />
            <Phone prefix={false} />
            <Email prefix={false} />
            <NIP prefix={true} />
            <REGON prefix={true} />
        </Task>
    )
}
const ExternalLink = ({ link, text }: { link: string; text: string }): ReactElement => {
    return (
        <StyledButton onClick={() => (window.location.href = link)} type={'button'}>
            {text}
        </StyledButton>
    )
}

const UsefulLinks = (): ReactElement => {
    const { t } = useTranslation()
    const lang = useCurrentLanguage()
    return (
        <Links>
            <SearchContactHeader>{t('footer-links')}</SearchContactHeader>
            <SearchContactText />
            <ExternalLink
                link={lang == 'EN' ? 'https://task.gda.pl/en/about/contact/' : 'https://task.gda.pl/o-nas/kontakt'}
                text={t('footer-links-contact')}
            />
            <ExternalLink
                link={
                    lang == 'EN'
                        ? 'https://task.gda.pl/en/resources/task-network/'
                        : 'https://task.gda.pl/pl/zasoby/siec/ '
                }
                text={t('footer-links-network')}
            />
            <ExternalLink
                link={
                    lang == 'EN'
                        ? 'https://task.gda.pl/en/about/ci-task-and-development-strategy/1'
                        : 'https://task.gda.pl/pl/o-nas/cel-misja-strategia/ '
                }
                text={t('footer-links-about')}
            />
        </Links>
    )
}

const InfoRow = ({ className }: WithClassName): ReactElement => {
    return (
        <StyledInfoRow className={className}>
            <InfoRowContent>
                <ContactInfo />
                <TaskInfo />
                <UsefulLinks />
            </InfoRowContent>
        </StyledInfoRow>
    )
}

const Footer = ({ className }: WithClassName): ReactElement => (
    <StyledFooter className={className}>
        <FooterSection />
        <InfoRow />
    </StyledFooter>
)

export default Footer
