import React, { ReactElement } from 'react'
import Loading from 'components/loading'
import { NavigateFunction } from 'react-router-dom'

const domainDetailsUrl = (domain: string): string => `/domains/${encodeURIComponent(domain)}`

const navigateToDomain = (navigate: NavigateFunction, newDomain: string | undefined): void => {
    const url = newDomain ? domainDetailsUrl(newDomain) : `/domains/`
    navigate(url)
}

export const navigateToMain = (navigate: NavigateFunction): void => {
    navigate('/')
}

const navigateToLogout = (navigate: NavigateFunction): void => {
    navigate('/logout/')
}

const Redirector = ({ path }: { path: string }): ReactElement => {
    window.location.replace(path)
    return <Loading />
}

const LoginRedirector = (): ReactElement => <Redirector path='/login/' />

const LogoutRedirector = (): ReactElement => <Redirector path='/logout/' />

const AccountRedirector = (): ReactElement => <Redirector path='/oauth/account/' />

const Paths = [
    {
        path: '/',
        exact: true,
        component: React.lazy(async () => import('pages/home')),
        title: 'header-home',
    },
    {
        path: '/domains/:domain',
        component: React.lazy(async () => import('pages/domain')),
        title: 'header-domain',
    },
    {
        path: '/domains',
        component: React.lazy(async () => import('pages/domains')),
        title: 'header-domains',
    },
    {
        path: '/check-domain/:domain',
        component: React.lazy(async () => import('pages/check-domain/check-domain')),
        title: 'header-check-domain',
    },
    {
        path: '/check-domain/',
        component: React.lazy(async () => import('pages/check-domain/check-domain')),
        title: 'header-check-domain',
    },
    {
        path: '/register-domain/:domain',
        component: React.lazy(async () => import('pages/register-domain')),
        title: 'header-register',
        fullWidth: true,
    },
    /*
    {
        path: '/price-domain/:domain',
        component: React.lazy(async () => import('pages/price-domain')),
        title: 'header-price',
    },
    */
    {
        path: '/registration-rules',
        component: React.lazy(async () => import('pages/registration-rules')),
        title: 'header-registration-rules',
    },
    {
        path: '/price-list',
        component: React.lazy(async () => import('pages/price-list')),
        title: 'header-price-list',
    },
    {
        path: '/faq',
        component: React.lazy(async () => import('pages/faq')),
        title: 'header-faq',
    },
    /*
    {
        path: '/summary/:domain/:period/:registered',
        component: React.lazy(async () => import('pages/summary')),
        title: 'header-summary',
    },
    */
    {
        path: '/login/',
        exact: true,
        component: LoginRedirector,
        title: 'nav-login',
    },
    {
        path: '/logout',
        component: LogoutRedirector,
        title: '',
    },
    {
        path: '/oauth/account',
        component: AccountRedirector,
        title: '',
    },
    {
        path: '/*',
        component: React.lazy(async () => import('pages/home')),
        title: 'header-home',
    },
]

export { navigateToDomain, navigateToLogout, domainDetailsUrl, Paths }
