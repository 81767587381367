import React, { ReactElement } from 'react'

import getLoadingText from 'api/language-patch'

const Loading = ({ text }: { text?: string }): ReactElement => {
    const content = text || getLoadingText()
    return <h1>{content}</h1>
}

export default Loading
